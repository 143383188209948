import React, { useEffect, useState } from "react";
import { InteractionModelFormData, InteractionModelFormProps } from "./InteractionModelForm";
import { FormikProps } from "formik";
import ApplicationIntentsEditor from "./ApplicationIntentsEditor";
import ApplicationNlpEntitiesEditor from "./ApplicationNlpEntitiesEditor";
import InteractionModelContainer from "../../../hooks/InteractionModelContainer";
import ApplicationNlpEntityContainer from "../../../hooks/ApplicationNlpEntityContainer";
import InteractionModelUiStateContainer from "../../../hooks/InteractionModelUiStateContainer";
import PageError from "../../../components/general/PageError";
import NlpSaveChangesModal from "./NlpSaveChangesModal";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import useWindowSize from "../../../hooks/UseWindowSize";
import VoicifyMenuItemsEditor from "./VoicifyMenuItemsEditor";
import ApplicationNlpMenuContainer from "../../../hooks/ApplicationNlpMenuContainer";
import EditedMenuItemModel from "../../../models/nlp/menu/EditedMenuItemModel";
import VoicifyGroupsEditor from "./VoicifyGroupsEditor";
import { v4 as uuidv4 } from 'uuid';
import { MenuItemOptionGroup } from "../../../models/nlp/menu/MenuItemOptionGroup";
import { MenuItemOption } from "../../../models/nlp/menu/MenuItemOption";
import EditedMenuItemOptionGroupModel from "../../../models/nlp/menu/EditedMenuItemOptionGroupModel";
import MenuItemModel from "../../../models/nlp/menu/MenuItemModel";
import EditedMenuItemSizeGroupModel from "../../../models/nlp/menu/EditedMenuItemSizeGroupModel";
import { MenuItemSizeGroup } from "../../../models/nlp/menu/MenuItemSizeGroup";
import { MenuItemSize } from "../../../models/nlp/menu/MenuItemSize";

export interface Matches {
    [groupId: string]: string[] // list of menu item ID's;
};

const InteractionModelEditorWrapper = (props: InteractionModelFormProps & FormikProps<InteractionModelFormData>) => {
    const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
    const [reformattedMenuItems, setReformattedMenuItems] = useState([] as EditedMenuItemModel[]);
    const [editedMenuItems, setEditedMenuItems] = useState([] as EditedMenuItemModel[]);
    const [selectedGroup, setSelectedGroup] = useState<EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel>(null);
    const [selectedMenuItem, setSelectedMenuItem] = useState<EditedMenuItemModel>();
    //option groups
    const [optionGroupsList, setOptionGroupsList] = useState<EditedMenuItemOptionGroupModel[]>();
    const [optionGroupMatches, setOptionGroupMatches] = useState<Matches[]>();
    const [areOptionGroups, setAreOptionGroups] = useState(false);
    const [latestChangedOptionGroup, setLatestChangedOptionGroup] = useState<MenuItemOptionGroup>(null);
    const [latestOptionGroupToReplace, setLatestOptionGroupToReplace] = useState<EditedMenuItemOptionGroupModel>(null);
    //size groups
    const [sizeGroupsList, setSizeGroupsList] = useState<EditedMenuItemSizeGroupModel[]>();
    const [sizeGroupMatches, setSizeGroupMatches] = useState<Matches[]>();
    const [areSizeGroups, setAreSizeGroups] = useState(false);
    const [latestChangedSizeGroup, setLatestChangedSizeGroup] = useState<MenuItemSizeGroup>(null);
    const [latestSizeGroupToReplace, setLatestSizeGroupToReplace] = useState<EditedMenuItemSizeGroupModel>(null);

    const interactionModelContainer = InteractionModelContainer.useContainer();
    const uiStateContainer = InteractionModelUiStateContainer.useContainer();
    const applicationNlpEntityContainer = ApplicationNlpEntityContainer.useContainer();
    const applicationNlpMenuContainer = ApplicationNlpMenuContainer.useContainer();
    const windowSize = useWindowSize();

    const appName = props.appContainer?.state?.currentApp?.name;

    useEffect(() => {
        applicationNlpMenuContainer.loadAllMenuItems(props.applicationId);
    }, []);

    useEffect(() => {
        const formattedMenuItems: EditedMenuItemModel[] = applicationNlpMenuContainer.menuItems.map((menuItem) => {
            return { menuItem: menuItem }
        });
        setReformattedMenuItems(formattedMenuItems);
        loadGroups(formattedMenuItems);
        const thereAreOptionGroups = formattedMenuItems?.some((menuItem) => {
            return menuItem?.menuItem?.optionGroups?.length > 0
        });
        const thereAreSizeGroups = formattedMenuItems?.some((menuItem) => {
            return menuItem?.menuItem?.sizeGroups?.length > 0
        });
        setAreOptionGroups(thereAreOptionGroups);
        setAreSizeGroups(thereAreSizeGroups);
    }, [applicationNlpMenuContainer.menuItems]);

    useEffect(() => {
        if (latestChangedOptionGroup) {
            performGroupChange(latestChangedOptionGroup, latestOptionGroupToReplace);
        } else {
            loadGroups(reformattedMenuItems);
        }
        if (latestChangedSizeGroup) {
            performGroupChange(latestChangedSizeGroup, latestSizeGroupToReplace);
        } else {
            loadGroups(reformattedMenuItems);
        }
    }, [uiStateContainer.menuEditorView])

    useEffect(() => {
        uiStateContainer.setIsFormDirty(props.dirty);
    }, [props.dirty]);

    useEffect(() => {
        if (location.pathname.includes("/nlp/entities")) {
            uiStateContainer.setNluTab("Entities");
        }
        else if (location.pathname.includes("/nlp/intents")) {
            uiStateContainer.setNluTab("Intents");
        }
        else if (location.pathname.includes("/nlp/menu")) {
            uiStateContainer.setNluTab("Menu");
        }
    }, [location.pathname]);

    useEffect(() => {
        uiStateContainer.setErrors(props.errors);
    }, [props.errors]);

    useEffect(() => {
        if (uiStateContainer.triggerSaveChanges && props.values.interactionModel && !(interactionModelContainer.isLoading || applicationNlpEntityContainer.isLoading)) {
            if (latestChangedOptionGroup) {
                performGroupChange(latestChangedOptionGroup, latestOptionGroupToReplace);
            }
            if (latestChangedSizeGroup) {
                performGroupChange(latestChangedSizeGroup, latestSizeGroupToReplace);
            }
            setShowSaveChangesModal(true);
            uiStateContainer.setTriggerSaveChanges(false);
        };
    }, [uiStateContainer.triggerSaveChanges]);

    const groupsMatch = (obj1: MenuItemOptionGroup | MenuItemSizeGroup, obj2: MenuItemOptionGroup | MenuItemSizeGroup) => {
        const excludeProperties = ['editId', 'referenceId', 'id', 'parentId'];
        const deepEqual = (val1, val2, exclude = []) => {
            if (val1 === val2) {
                return true;
            }
            if (typeof val1 !== 'object' || val1 === null || typeof val2 !== 'object' || val2 === null) {
                return false;
            }
            if (Array.isArray(val1) && Array.isArray(val2)) {
                if (val1.length !== val2.length) {
                    return false;
                }
                for (let i = 0; i < val1.length; i++) {
                    if (!deepEqual(val1[i], val2[i], exclude)) {
                        return false;
                    }
                }
                return true;
            }
            const keys1 = Object.keys(val1);
            const keys2 = Object.keys(val2);
            const validKeys1 = keys1.filter(key => !exclude.includes(key));
            const validKeys2 = keys2.filter(key => !exclude.includes(key));
            if (validKeys1.length !== validKeys2.length) {
                return false;
            }
            for (const key of validKeys1) {
                if (!validKeys2.includes(key)) {
                    return false;
                }
                if (!deepEqual(val1[key], val2[key], exclude)) {
                    return false;
                }
            }
            return true;
        };
        return deepEqual(obj1, obj2, excludeProperties);
    };

    const getAllOptionGroups = (item: MenuItemModel | MenuItemOption | MenuItemSize): MenuItemOptionGroup[] => {
        const optionGroupsList: MenuItemOptionGroup[] = [...(item.optionGroups ?? [])];
        for (const optionGroup of item.optionGroups) {
            for (const option of optionGroup?.options) {
                if (option?.optionGroups?.length > 0) {
                    optionGroupsList.push(...getAllOptionGroups(option));
                };
            };
        };
        if ('sizeGroups' in item) {
            for (const sizeGroup of item.sizeGroups) {
                for (const size of sizeGroup?.sizes) {
                    if (size?.optionGroups?.length > 0) {
                        optionGroupsList.push(...getAllOptionGroups(size));
                    };
                }
            };
        }
        return optionGroupsList;
    };

    const sortOptionsOrSizes = (sizesOrOptions: (MenuItemOption | MenuItemSize)[]) => {
        sizesOrOptions?.sort((a, b) => {
            if (!a || !a.name) {
                return -1;
            }
            if (!b || !b.name) {
                return 1;
            }
            const key1 = optionKey(a);
            const key2 = optionKey(b);
            const nameA = key1.toLowerCase();
            const nameB = key2.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    const createOptionGroupForEditing = (optionGroup: MenuItemOptionGroup): EditedMenuItemOptionGroupModel => {
        let editedOptionGroup: EditedMenuItemOptionGroupModel;
        if (!optionGroup) {
            editedOptionGroup = {
                optionGroup: {
                    referenceId: undefined,
                    name: undefined,
                    colloquialName: undefined,
                    numberOfRequiredOptions: undefined,
                    maxSelections: undefined,
                    limiter: undefined,
                    promptOverride: undefined,
                    initialPromptOverride: undefined,
                    simpleInitialPromptOverride: undefined,
                    numberedInitialPromptOverride: undefined,
                    automaticallyAddDefault: undefined,
                    isRequired: undefined,
                    promptIfNoSelections: undefined,
                    options: []
                },
                editId: undefined
            };
        } else {
            editedOptionGroup = {
                optionGroup: {
                    ...optionGroup,
                    editId: undefined,
                    referenceId: undefined,
                    options: (optionGroup.options?.map((option) => {
                        let editedOption: MenuItemOption;
                        if (!option) {
                            editedOption = {
                                id: undefined,
                                parentId: undefined,
                                name: undefined,
                                colloquialName: undefined,
                                modifier: undefined,
                                default: undefined,
                                fixed: undefined,
                                isPrimaryOption: undefined,
                                ignoreForReadout: undefined,
                                hasQuantity: undefined,
                                defaultQuantity: undefined,
                                minQuantity: undefined,
                                maxQuantity: undefined,
                                disableInferencing: undefined,
                                optionGroups: undefined,
                                editId: undefined
                            };
                        } else {
                            editedOption = option;
                        }
                        return {
                            ...editedOption,
                            optionGroups: undefined,
                            id: undefined,
                            parentId: undefined
                        };
                    }) || [])
                }
            };
        }
        editedOptionGroup.optionGroup.options?.forEach((o) => {
            if (o) {
                const excludeProperties = ['editId', 'referenceId', 'id', 'parentId', 'options'];
                Object.keys(o).forEach(key => {
                    if (!excludeProperties.includes(key) &&
                        o[key] === null || o[key] === undefined) {
                        delete o[key];
                    }
                });
            }
        })
        sortOptionsOrSizes(editedOptionGroup.optionGroup.options);
        return editedOptionGroup;
    };

    const createSizeGroupForEditing = (menuItemSizeGroup: MenuItemSizeGroup): EditedMenuItemSizeGroupModel => {
        let editedSizeGroup: EditedMenuItemSizeGroupModel;
        if (!menuItemSizeGroup) {
            editedSizeGroup = {
                sizeGroup: {
                    numberOfRequiredSizes: undefined,
                    promptOverride: undefined,
                    sizes: [],
                    initialPromptOverride: undefined,
                    numberedInitialPromptOverride: undefined
                },
                editId: undefined
            };
        } else {
            editedSizeGroup = {
                sizeGroup: {
                    ...menuItemSizeGroup,
                    editId: undefined,
                    sizes: (menuItemSizeGroup.sizes?.map((size) => {
                        let editedSize: MenuItemSize;
                        if (!size) {
                            editedSize = {
                                id: undefined,
                                name: undefined,
                                colloquialName: undefined,
                                default: undefined,
                                fixed: undefined,
                                ignoreForReadout: undefined,
                                disableInferencing: undefined,
                                optionGroups: undefined
                            };
                        } else {
                            editedSize = size;
                        }
                        return {
                            ...editedSize,
                            optionGroups: undefined,
                            id: undefined,
                            parentId: undefined
                        };
                    }) || [])
                }
            };
        }
        editedSizeGroup.sizeGroup.sizes?.forEach((size) => {
            if (sizeGroupMatches) {
                const excludeProperties = ['editId', 'sizes'];
                Object.keys(setSizeGroupsList).forEach(key => {
                    if (!excludeProperties.includes(key) &&
                        size[key] === null || size[key] === undefined) {
                        delete size[key];
                    }
                });
            }
        })
        sortOptionsOrSizes(editedSizeGroup.sizeGroup.sizes);
        return editedSizeGroup;
    };

    const findGroupsToUpdate = (
        groups: (MenuItemOptionGroup | MenuItemSizeGroup)[],
        itemToReplace: (EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel | undefined),
        allToUpdate: (MenuItemOptionGroup | MenuItemSizeGroup)[]
    ) => {
        if (groups) {
            for (const group of groups) {
                if ('editId' in group && (itemToReplace ? itemToReplace.editId : selectedGroup.editId) === group.editId) {
                    allToUpdate.push(group);
                }
                if ('options' in group && group.options) {
                    for (const option of group.options) {
                        findGroupsToUpdate(option.optionGroups || [], itemToReplace, allToUpdate);
                    }
                }
            }
        }
    };

    const performGroupChange = (changedGroup: (MenuItemOptionGroup | MenuItemSizeGroup), groupToReplace?: (EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel)) => {
        const editedMenuItemsCopy = JSON.parse(JSON.stringify(editedMenuItems));
        let matchingMenuItemIds: Matches;
        if ('options' in changedGroup) {
            matchingMenuItemIds = optionGroupMatches.find(
                (match) => match[groupToReplace ? groupToReplace.editId : selectedGroup.editId]
            );
        } else if ('sizes' in changedGroup) {
            matchingMenuItemIds = sizeGroupMatches.find(
                (match) => match[groupToReplace ? groupToReplace.editId : selectedGroup.editId]
            );
        }
        if (matchingMenuItemIds) {
            for (const menuItemId of matchingMenuItemIds[groupToReplace ? groupToReplace.editId : selectedGroup.editId]) {
                // for each menu item that has the selected group:
                const menuItemToUpdate: EditedMenuItemModel = editedMenuItemsCopy.find((menuItem: EditedMenuItemModel) => menuItem.menuItem.id === menuItemId)
                if (menuItemToUpdate) {
                    let groupsToUpdate: MenuItemOptionGroup[] | MenuItemSizeGroup[] = [];
                    if (uiStateContainer.menuEditorView === "Option Groups" && menuItemToUpdate.menuItem.optionGroups.length) {
                        if (groupToReplace) {
                            findGroupsToUpdate(menuItemToUpdate.menuItem.optionGroups, groupToReplace, groupsToUpdate);
                        } else {
                            const allOptionGroups: MenuItemOptionGroup[] = [];
                            for (const sizeGroups of menuItemToUpdate?.menuItem?.sizeGroups) {
                                for (const sizeGroupSize of sizeGroups?.sizes) {
                                    if (sizeGroupSize?.optionGroups?.length) {
                                        allOptionGroups.push(...sizeGroupSize?.optionGroups);
                                    }
                                }
                            }
                            menuItemToUpdate.menuItem.optionGroups.push(...allOptionGroups);
                            findGroupsToUpdate(menuItemToUpdate.menuItem.optionGroups, null, groupsToUpdate);
                        }
                    }
                    // for optionGroups within size
                    if (uiStateContainer.menuEditorView === "Option Groups" && !menuItemToUpdate.menuItem.optionGroups.length) {
                        for (const sizeGroups of menuItemToUpdate.menuItem.sizeGroups) {
                            for (const sizeGroupSize of sizeGroups.sizes) {
                                if (groupToReplace) {
                                    findGroupsToUpdate(sizeGroupSize.optionGroups, groupToReplace, groupsToUpdate);
                                } else {
                                    findGroupsToUpdate(sizeGroupSize.optionGroups, null, groupsToUpdate);
                                }
                            }
                        }
                    }
                    if (uiStateContainer.menuEditorView === "Size Groups") {
                        if (groupToReplace) {
                            findGroupsToUpdate(menuItemToUpdate.menuItem.sizeGroups, groupToReplace, groupsToUpdate);
                        } else {
                            findGroupsToUpdate(menuItemToUpdate.menuItem.sizeGroups, null, groupsToUpdate);
                        }
                    }
                    if (groupsToUpdate?.length) {
                        for (const groupToUpdate of groupsToUpdate) {
                            if (groupToUpdate) {
                                menuItemToUpdate.isModified = true;
                                // update options or sizes
                                const excludeProperties = ['editId', 'referenceId', 'id', 'parentId', 'options', 'sizes'];
                                Object.keys(changedGroup).forEach((property) => {
                                    if (!excludeProperties.includes(property)) {
                                        groupToUpdate[property] = changedGroup[property];
                                    }
                                    // remove properties that are not in changedGroup
                                    Object.keys(groupToUpdate).forEach((prop) => {
                                        if (!Object.keys(changedGroup).includes(prop)) {
                                            delete groupToUpdate[prop];
                                        }
                                    });
                                });
                                const optionsOrSizesToRemove = [];
                                let groupToUpdateOptionsOrSizes: MenuItemOption[] | MenuItemSize[] = [];
                                let changedGroupOptionsOrSizes: MenuItemOption[] | MenuItemSize[] = [];
                                if ('options' in groupToUpdate && 'options' in changedGroup) {
                                    groupToUpdateOptionsOrSizes = groupToUpdate.options;
                                    changedGroupOptionsOrSizes = changedGroup.options;
                                } else if ('sizes' in groupToUpdate && 'sizes' in changedGroup) {
                                    groupToUpdateOptionsOrSizes = groupToUpdate.sizes;
                                    changedGroupOptionsOrSizes = changedGroup.sizes;
                                }
                                // if groupToUpdate has options or sizes that are not in changedGroup, remove them
                                groupToUpdateOptionsOrSizes.forEach((optionOrSizeToUpdate) => {
                                    if (!changedGroupOptionsOrSizes.find((changedOptionOrSize) => groupToReplace ? optionKey(changedOptionOrSize) === optionKey(optionOrSizeToUpdate) : changedOptionOrSize.editId === optionOrSizeToUpdate.editId)) {
                                        optionsOrSizesToRemove.push(optionOrSizeToUpdate);
                                    }
                                });
                                optionsOrSizesToRemove.forEach((optionOrSizeToRemove) => {
                                    let optionOrSizeToRemoveIndex: number;
                                    if ('options' in groupToUpdate) {
                                        optionOrSizeToRemoveIndex = groupToUpdate.options.findIndex((option) => optionKey(option) === optionKey(optionOrSizeToRemove));
                                        groupToUpdate.options.splice(optionOrSizeToRemoveIndex, 1);
                                    } else if ('sizes' in groupToUpdate) {
                                        optionOrSizeToRemoveIndex = groupToUpdate.sizes.findIndex((size) => optionKey(size) === optionKey(optionOrSizeToRemove));
                                        groupToUpdate.sizes.splice(optionOrSizeToRemoveIndex, 1);
                                    }
                                });
                                changedGroupOptionsOrSizes.forEach((changedOptionOrSize) => {
                                    let groupToUpateOptionOrSize: MenuItemOption | MenuItemSize;
                                    if ('options' in groupToUpdate) {
                                        groupToUpateOptionOrSize = groupToUpdate.options.find((option) => {
                                            return groupToReplace ? optionKey(option) === optionKey(changedOptionOrSize) : option.editId === changedOptionOrSize.editId;
                                        });
                                    } else if ('sizes' in groupToUpdate) {
                                        groupToUpateOptionOrSize = groupToUpdate.sizes.find((size) => {
                                            return groupToReplace ? optionKey(size) === optionKey(changedOptionOrSize) : size.editId === changedOptionOrSize.editId;
                                        });
                                    }
                                    if (groupToUpateOptionOrSize) {
                                        const excludeProperties = ['editId', 'referenceId', 'id', 'parentId', 'options', 'optionGroups'];
                                        Object.keys(groupToUpateOptionOrSize).forEach((key) => {
                                            if (!excludeProperties.includes(key)) {
                                                if (key in changedOptionOrSize) {
                                                    groupToUpateOptionOrSize[key] = changedOptionOrSize[key];
                                                } else {
                                                    delete groupToUpateOptionOrSize[key];
                                                }
                                            }
                                            //if changedOptionOrSize has a property that groupToUpdateOptionOrSize does not, add it
                                            Object.keys(changedOptionOrSize).forEach((key) => {
                                                if (!(key in groupToUpateOptionOrSize)) {
                                                    groupToUpateOptionOrSize[key] = changedOptionOrSize[key];
                                                }
                                            });
                                        });
                                    } else {
                                        if ('options' in groupToUpdate) {
                                            groupToUpdate.options.push(changedOptionOrSize);
                                        } else if ('sizes' in groupToUpdate) {
                                            groupToUpdate.sizes.push(changedOptionOrSize);
                                        }
                                    }
                                });
                                // update groups
                                groupToUpdate.editId = changedGroup.editId;
                                if ('options' in groupToUpdate) {
                                    sortOptionsOrSizes(groupToUpdate.options);
                                } else if ('sizes' in groupToUpdate) {
                                    sortOptionsOrSizes(groupToUpdate.sizes);
                                }
                            }
                        }
                    }
                }
            }
            setReformattedMenuItems(editedMenuItemsCopy);
            setEditedMenuItems(editedMenuItemsCopy);
            setLatestChangedOptionGroup(null);
            setLatestChangedSizeGroup(null);
            setLatestOptionGroupToReplace(null);
            setLatestSizeGroupToReplace(null);
        }
    };

    const optionKey = (option: MenuItemOption): string => {
        return `${option?.name ?? "unknown-name"}-${option.modifier ?? "unknown-modifier"}`;
    };

    const loadGroups = (menuItems: EditedMenuItemModel[]) => {
        const uniqueGroups: (EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel)[] = [];
        const matchingGroups: Matches[] = [];
        const updatedMenuItems = [...menuItems];
        for (const updatedMenuItem of updatedMenuItems) {
            let groupsForMenuItem: MenuItemOptionGroup[] | MenuItemSizeGroup[] = [];
            if (uiStateContainer.menuEditorView === "Option Groups") {
                groupsForMenuItem = getAllOptionGroups(updatedMenuItem.menuItem);
            } else if (uiStateContainer.menuEditorView === "Size Groups") {
                groupsForMenuItem = updatedMenuItem.menuItem.sizeGroups;
            }
            for (const group of groupsForMenuItem) {
                let editedGroup: EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel;
                if ('options' in group) {
                    editedGroup = createOptionGroupForEditing(group);
                } else if ('sizes' in group) {
                    editedGroup = createSizeGroupForEditing(group);
                }
                let foundMatch = false;
                for (const uniqueGroup of uniqueGroups) {
                    let theyMatch = false;
                    if ('optionGroup' in editedGroup && 'optionGroup' in uniqueGroup) {
                        theyMatch = groupsMatch(editedGroup.optionGroup, uniqueGroup.optionGroup);
                    } else if ('sizeGroup' in editedGroup && 'sizeGroup' in uniqueGroup) {
                        theyMatch = groupsMatch(editedGroup.sizeGroup, uniqueGroup.sizeGroup);
                    }
                    if (theyMatch) {
                        foundMatch = true;
                        group.editId = uniqueGroup.editId;
                        if ('options' in group && 'optionGroup' in uniqueGroup) {
                            for (const optionGroupOption of group.options) {
                                optionGroupOption.editId = uniqueGroup.optionGroup.options.find((uniqueOptionGroupOption: MenuItemOption) => {
                                    return optionKey(uniqueOptionGroupOption) === optionKey(optionGroupOption);
                                })?.editId;
                            }
                        } else if ('sizes' in group && 'sizeGroup' in uniqueGroup) {
                            for (const sizeGroupSize of group.sizes) {
                                sizeGroupSize.editId = uniqueGroup.sizeGroup.sizes.find((uniqueSizeGroupSize: MenuItemSize) => {
                                    return optionKey(uniqueSizeGroupSize) === optionKey(sizeGroupSize);
                                })?.editId;
                            }
                        }
                        const matchIndex = matchingGroups.findIndex((match) => {
                            return match[group.editId];
                        });
                        // add menu item id to existing optionGroup
                        if (!matchingGroups[matchIndex][group.editId].includes(updatedMenuItem.menuItem.id)) {
                            matchingGroups[matchIndex][group.editId].push(updatedMenuItem.menuItem.id);
                        }
                        break;
                    }
                }
                if (!foundMatch) {
                    editedGroup.editId = uuidv4();
                    group.editId = editedGroup.editId;
                    if ('options' in group && 'optionGroup' in editedGroup) {
                        for (const optionGroupOption of group.options) {
                            optionGroupOption.editId = uuidv4();
                            const option = editedGroup.optionGroup.options.find((uniqueOptionGroupOption: MenuItemOption) => {
                                return optionKey(uniqueOptionGroupOption) === optionKey(optionGroupOption);
                            });
                            option.editId = optionGroupOption.editId;
                        }
                    } else if ('sizes' in group && 'sizeGroup' in editedGroup) {
                        for (const sizeGroupSize of group.sizes) {
                            sizeGroupSize.editId = uuidv4();
                            const size = editedGroup.sizeGroup.sizes.find((uniqueSizeGroupSize: MenuItemSize) => {
                                return optionKey(uniqueSizeGroupSize) === optionKey(sizeGroupSize);
                            });
                            size.editId = sizeGroupSize.editId;
                        }
                    }
                    uniqueGroups.push(editedGroup);
                    matchingGroups.push({ [editedGroup.editId]: [updatedMenuItem.menuItem.id] });
                }
            };
            // sort option groups alphabetically
            if (uiStateContainer.menuEditorView === "Option Groups") {
                uniqueGroups.sort((a, b) => {
                    if ('optionGroup' in a && 'optionGroup' in b) {
                        if (a.optionGroup.name < b.optionGroup.name) {
                            return -1;
                        }
                        if (a.optionGroup.name > b.optionGroup.name) {
                            return 1;
                        }
                    }
                    return 0;
                });
            }
            setEditedMenuItems(updatedMenuItems);
            if (uiStateContainer.menuEditorView === "Option Groups") {
                setOptionGroupsList(uniqueGroups as EditedMenuItemOptionGroupModel[]);
                setOptionGroupMatches(matchingGroups);
            } else {
                setSizeGroupsList(uniqueGroups as EditedMenuItemSizeGroupModel[]);
                setSizeGroupMatches(matchingGroups);
            }
        }
    };

    const submitChanges = async () => {
        // submit interaction model form
        props.submitForm();
        // submit menu item updates
        if (reformattedMenuItems.length > 0) {
            let reload = false;
            for (const menuItem of reformattedMenuItems) {
                if (selectedGroup) {
                    setSelectedGroup(null);
                }
                if (selectedMenuItem) {
                    setSelectedMenuItem(null);
                }
                if (menuItem?.isDeleted) {
                    reload = true;
                    await applicationNlpMenuContainer.deleteVoicifyMenuItem(props.applicationId, menuItem.menuItem.id);
                }
                else if (menuItem?.isAdded) {
                    reload = true;
                    await applicationNlpMenuContainer.addVoicifyMenuItem(props.applicationId, menuItem.menuItem);
                }
                else if (menuItem?.isModified) {
                    reload = true;
                    await applicationNlpMenuContainer.updateVoicifyMenuItem(props.applicationId, menuItem.menuItem);
                }
            }
            if (reload) {
                setReformattedMenuItems([]);
                await applicationNlpMenuContainer.loadAllMenuItems(props.applicationId);
            }
        }
    }

    const closeSaveChangesModal = () => {
        setShowSaveChangesModal(false);
    };

    return <>
        {uiStateContainer.nluTab === "Intents" &&
            <CustomScrollbars autoHide autoHeight autoHeightMax={windowSize.windowSize.innerHeight - 210}>
                <ApplicationIntentsEditor
                    {...props}
                    applicationId={props.applicationId}
                    key={props.languages.map(l => l.id).join("|")}
                    languages={props.languages}
                    interactionModel={props.values.interactionModel}
                    isLoading={(interactionModelContainer.isLoading || applicationNlpEntityContainer.isLoading)}
                />
            </CustomScrollbars>
        }

        {uiStateContainer.nluTab === "Entities" &&
            <ApplicationNlpEntitiesEditor
                {...props}
                nlpEntities={props.values.nlpEntities}
                applicationId={props.applicationId}
                key={props.languages.map(l => l.id).join("|")}
                languages={props.languages}
                allAppLanguages={props.allAppLanguages}
                isLoading={(interactionModelContainer.isLoading || applicationNlpEntityContainer.isLoading)}
            />
        }

        {uiStateContainer.menuEditorView === "Menu Items" && uiStateContainer.nluTab === "Menu" &&
            <VoicifyMenuItemsEditor
                {...props}
                updatedMenuItems={reformattedMenuItems}
                setUpdatedMenuItems={setReformattedMenuItems}
                applicationId={props.applicationId}
                isLoading={applicationNlpMenuContainer.isLoading}
                selectedMenuItem={selectedMenuItem}
                setSelectedMenuItem={setSelectedMenuItem}
                menuItems={applicationNlpMenuContainer.menuItems}
                appName={appName}
            />
        }

        {uiStateContainer.menuEditorView === "Option Groups" && uiStateContainer.nluTab === "Menu" &&
            <VoicifyGroupsEditor
                {...props}
                performGroupChange={performGroupChange}
                setSelectedGroup={setSelectedGroup}
                groupsList={optionGroupsList}
                setGroupsList={setOptionGroupsList}
                setLatestChangedGroup={setLatestChangedOptionGroup}
                latestChangedGroup={latestChangedOptionGroup}
                latestGroupToReplace={latestOptionGroupToReplace}
                setLatestGroupToReplace={setLatestOptionGroupToReplace}
                matches={optionGroupMatches}
                selectedGroup={selectedGroup}
                areGroups={areOptionGroups}
                groupType="optionGroup"
                // menu items for editing purposes
                editableMenuItems={editedMenuItems}
                setEditableMenuItems={setEditedMenuItems}
                // formatted menu items unedited
                originalMenuItems={reformattedMenuItems}
                setOriginalMenuItems={setReformattedMenuItems}
                menuItems={applicationNlpMenuContainer.menuItems}
                applicationId={props.applicationId}
                isLoading={applicationNlpMenuContainer.isLoading}
            />
        }

        {uiStateContainer.menuEditorView === "Size Groups" && uiStateContainer.nluTab === "Menu" &&
            <VoicifyGroupsEditor
                {...props}
                performGroupChange={performGroupChange}
                setSelectedGroup={setSelectedGroup}
                groupsList={sizeGroupsList}
                setGroupsList={setSizeGroupsList}
                setLatestChangedGroup={setLatestChangedSizeGroup}
                latestChangedGroup={latestChangedSizeGroup}
                latestGroupToReplace={latestSizeGroupToReplace}
                setLatestGroupToReplace={setLatestSizeGroupToReplace}
                matches={sizeGroupMatches}
                selectedGroup={selectedGroup}
                areGroups={areSizeGroups}
                groupType="sizeGroup"
                // menu items for editing purposes
                editableMenuItems={editedMenuItems}
                setEditableMenuItems={setEditedMenuItems}
                // formatted menu items unedited
                originalMenuItems={reformattedMenuItems}
                setOriginalMenuItems={setReformattedMenuItems}
                menuItems={applicationNlpMenuContainer.menuItems}
                applicationId={props.applicationId}
                isLoading={applicationNlpMenuContainer.isLoading}
            />
        }

        <PageError errors={[
            ...interactionModelContainer?.errors ?? [],
            ...applicationNlpEntityContainer?.errors ?? []
        ]}
            onClear={() => {
                interactionModelContainer.clearErrors();
                applicationNlpEntityContainer.clearErrors();
            }} />

        <NlpSaveChangesModal
            showSaveChangesModal={showSaveChangesModal}
            closeModal={closeSaveChangesModal}
            values={props.values}
            submitChanges={submitChanges}
            languages={props.languages}
            isLoading={(interactionModelContainer.isLoading || applicationNlpEntityContainer.isLoading)}
            errors={props.errors}
            updatedMenuItems={reformattedMenuItems}
        />
    </>
};

export default InteractionModelEditorWrapper;
