import React, { useEffect, useRef, useState } from 'react';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';
import styled from '@emotion/styled';
import TotalCard from '../../../components/charts/TotalCard';
import Loader from '../../../components/general/Loader';
import PageError from '../../../components/general/PageError';
import Button from '../../../components/general/Button';
import { Link } from 'react-router-dom';
import { color_colors_ocean, color_shades_dark, color_shades_light, color_variants_blue_2 } from '../../../constants/colors';
import { css } from '@emotion/css';
import OrgAnalyticsContainer from '../../../hooks/OrgAnalyticsContainer';
import ModalLayout from '../../../components/structure/ModalLayout';
import ModalHeader from '../../../components/general/ModalHeader';
import SessionReview from '../../applicationAnalytics/components/SessionReview';
import useWindowSize from '../../../hooks/UseWindowSize';
import { SESSION_DATE_FORMAT } from '../../../models/analytics/AnalyticsDateFormat';
import moment from 'moment';
import CollapsingPanel from '../../../components/structure/Panels/CollapsingPanel';
import FooterBar from '../../../components/structure/FooterBar';
import { getBaseUrl } from '../../../constants/Urls';
import CodeOutputModal from '../../simulator/components/CodeOutputModal';
import { isEqual } from 'lodash';

const conversationalSuccessIcon = require("../../../content/images/analytics-icons/conversational-success.svg");
const orderPlacedIcon = require("../../../content/images/analytics-icons/order-placed.svg");

interface ApplicationOrderingWrapperProps {
    filter: AnalyticsRequestFilter
}

const OrganizationOrderingAnalyticsReport = (props: ApplicationOrderingWrapperProps) => {
    const [eventsToReview, setEventsToReview] = useState([]);
    const [sessionIdToReview, setSessionIdToReview] = useState("");
    const [expandFailedOrders, setExpandFailedOrders] = useState(true);
    const [eventsToReviewInCode, setEventsToReviewInCode] = useState([]);
    const [expandNoOrders, setExpandNoOrders] = useState(false);
    const [expandPlacedOrders, setExpandPlacedOrder] = useState(false);
    const [expandOrderTransfers, setExpandOrderTransfers] = useState(false);
    const [applicationId, setApplicationId] = useState("")

    const [copyButtonText, setCopyButtonText] = useState("Copy Session");

    const orgAnalyticsContainer = OrgAnalyticsContainer.useContainer();
    const filterRef = useRef(null)
    const windowSize = useWindowSize();

    useEffect(() => {
        const currentOrgId = JSON.parse(localStorage.getItem("CURRENT_ORG"))?.id;
        if (!currentOrgId) return;
        if (!isEqual(filterRef.current, props.filter)) {
            orgAnalyticsContainer.loadOrgAnalytics(currentOrgId, props.filter).then(res => {
                orgAnalyticsContainer.getSessionsOfAllApplications(currentOrgId, props.filter);
            })

            filterRef.current = props.filter
        }

    }, [props.filter]);


    useEffect(() => {
        let load = false;
        if (sessionIdToReview?.length) {
            if (eventsToReview?.length) {
                if (eventsToReview[0].sessionId !== sessionIdToReview) {
                    load = true;
                }
            } else {
                load = true;
            }
        }
        if (load) {
            if (sessionIdToReview in orgAnalyticsContainer.allEventsCache) {
                setEventsToReview(orgAnalyticsContainer.allEventsCache[sessionIdToReview]);
            } else {
                orgAnalyticsContainer.loadAllEventsForSession(sessionIdToReview, applicationId);
            }
        }
    }, [sessionIdToReview, eventsToReview, orgAnalyticsContainer.allEventsCache])
    const convertEventsForCodeOutputModal = (events: any[]) => {
        var returnObj = {};
        events?.forEach((e, idx) => {
            returnObj[`${idx}-${e.eventType}`] = e;
        })

        // get last event that is request received
        const lastContentHit = events?.slice()?.reverse()?.find(e => e.eventType === "ContentHit");
        if (lastContentHit?.originalRequest?.sessionAttributes) {
            returnObj[`${events.length}-LatestSessionAttributes`] = lastContentHit.originalRequest.sessionAttributes;
        }

        return returnObj;
    }
    const CodeReviewModal = () => {
        return (
            <CodeOutputModal
                title="JSON"
                closeModal={() => setEventsToReviewInCode(null)}
                data={convertEventsForCodeOutputModal(eventsToReviewInCode)}
                showCodeOutputModal={eventsToReviewInCode?.length > 0}
            />);
    }
    const clearSessionToReview = () => {
        setEventsToReview([]);
        setSessionIdToReview("");
    }
    const resetCopyButtonText = () => {
        setCopyButtonText("Copy Session");
    }
    const formatPercentage = (value: number) => {
        if (!value) {
            return "0 %"
        }
        if (value < 0) {
            return "No orders";
        }
        return `${(value * 100).toFixed(2)} %`
    }
    const handleCopyButtonClick = (url: string) => {
        navigator.clipboard.writeText(url);
        setCopyButtonText("Copied!");
    }
    const getStartandEndDate = (sessionId: string) => {
        const session = orgAnalyticsContainer.sessions?.find(s => s.sessionId === sessionId);
        if (session) {
            const weekBeforeStartDate = new Date(new Date(session?.startDate).getTime() - (7 * 86400000)).toISOString().split("T")[0];
            const dayAfterStartDate = new Date(new Date(session?.startDate).getTime() + 86400000).toISOString().split("T")[0];
            return `&startDate=${weekBeforeStartDate}&endDate=${dayAfterStartDate}`;
        }
    }

    const CustomSessionReview = () => {
        if (!eventsToReview?.length)
            return <></>;
        const e = (eventsToReview?.length && eventsToReview[0]) ? eventsToReview[0] : null;
        return (
            <div onClick={(e) => { e.stopPropagation() }}>
                <ModalLayout isVisible>
                    <ModalHeader title={moment(e?.eventDate).format(SESSION_DATE_FORMAT)} onClose={() => { clearSessionToReview(); resetCopyButtonText() }} />
                    <SessionReview
                        inModal
                        manualHeight={(windowSize.windowSize.innerHeight * .9) - 168}
                        events={eventsToReview ?? []}
                        applicationId={applicationId}
                        setEventsToShow={setEventsToReviewInCode}
                        resetCopyButtonText={resetCopyButtonText}
                        sessionId={e?.sessionId}
                    />
                    <FooterBar>
                        <Link
                            to={`/v/apps/${e?.applicationId}/analytics/sessions?sessionId=${e.sessionId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                                e.stopPropagation();
                                resetCopyButtonText();
                            }}
                        >
                            <Button
                                disabled={false}
                                themes={['secondary']}
                                text="Go To Session"
                                onClick={null}
                                type="button" />
                        </Link>
                        <div>
                            <Button
                                disabled={false}
                                themes={['secondary']}
                                text={copyButtonText}
                                onClick={() => handleCopyButtonClick(`${getBaseUrl()}/v/apps/${e?.applicationId}/analytics/sessions?sessionId=${e.sessionId}${getStartandEndDate(e.sessionId)}`)}
                                type="button" />
                        </div>
                        <FooterButton
                            type="button"
                            themes={["secondary"]}
                            text="Close"
                            onClick={(e) => {
                                clearSessionToReview();
                                e.stopPropagation();
                                resetCopyButtonText();
                            }}
                        />
                    </FooterBar>
                </ModalLayout>
            </div>
        )
    }
    const renderCollapsableSessionReview = (idsToReview: string[], title: string, expandBool: boolean, setBoolFunc: (b: boolean) => void) => {
        return (<CollapsingPanel
            collapsedView={<ClickableHeader onClick={() => setBoolFunc(!expandBool)}>{`${title} (${idsToReview.length})`}</ClickableHeader>}
            isCollapsed={!expandBool}
            expandedView={<>
                <ClickableHeader onClick={() => { setBoolFunc(!expandBool) }}>{`${title} (${idsToReview.length})`}</ClickableHeader>
                {idsToReview.map((sessionId, idx) => {
                    const session = orgAnalyticsContainer?.sessions?.find(s => s.sessionId === sessionId)
                    return (
                        <SessionHeader
                            className={orgAnalyticsContainer.loadingSessionId === sessionId ? 'loading' : ""}
                            key={sessionId}
                            onClick={() => {
                                if (!orgAnalyticsContainer.loadingSessionId.length) {
                                    setSessionIdToReview(sessionId)
                                }
                                setApplicationId(session?.applicationId)
                            }}>
                            <SessionHeaderDate>
                                {moment(session?.startDate ?? new Date()).format(SESSION_DATE_FORMAT)}
                            </SessionHeaderDate>
                            <SessionLink
                                to={`/v/apps/${session?.applicationId}/analytics/sessions?sessionId=${sessionId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                            >
                                <SessionLinkButton
                                    disabled={false}
                                    themes={['secondary-small']}
                                    text="Full Session"
                                    onClick={null}
                                    type="button" />
                            </SessionLink>
                        </SessionHeader>
                    )
                })}
            </>}
        />);
    };
    if (orgAnalyticsContainer.loadingOrgAnalytics || orgAnalyticsContainer.loadingCallStatistics || orgAnalyticsContainer.loadingTopIntents || orgAnalyticsContainer.loadingOrdersPlaced || orgAnalyticsContainer.loadingItemAddedToOrder ||
        orgAnalyticsContainer.loadingConversationalSucess || orgAnalyticsContainer.loadingCallTransferredEvents || orgAnalyticsContainer.loadingImmediateCallTransferredEvents)
        return <Loader />

    return <>
        <Header>Organizational Ordering Insights</Header>
        <SummaryContainer>
            <StyledTotalCard
                title="Conversational Success Rate"
                subtitle="(All Calls)"
                value={formatPercentage(orgAnalyticsContainer.conversationalSuccessRate)}
                subValue={`${orgAnalyticsContainer.conversationalSuccessCount?.toString()} ${orgAnalyticsContainer.conversationalSuccessCount === 1 ? ' successful call' : ' successful calls'}`}
                tooltip='Calls marked as Conversational Success. This includes AI Answering queries, immediate hangups, and successful transfers.'
                highlight={true}
                className={highlightedTotalCardStyle}
                icon={conversationalSuccessIcon}
            />
            <StyledTotalCard
                title="Conversational Success Rate"
                subtitle="(Attempted Orders)"
                value={formatPercentage(orgAnalyticsContainer.attemptedOrdersConversationalSuccessRate)}
                subValue={`${orgAnalyticsContainer.conversationalSuccessCount} ${orgAnalyticsContainer.conversationalSuccessCount === 1 ? ' successful call' : ' successful calls'}`}
                tooltip='Orders Placed plus Unplaced Orders marked as Conversational Success divided by the number of Attempted Orders.'
                highlight={true}
                className={highlightedTotalCardStyle}
                icon={conversationalSuccessIcon}
            />
            <StyledTotalCard
                title="Orders Placed Rate"
                subtitle="(Attempted Orders)"
                value={formatPercentage(orgAnalyticsContainer.orderPlacedRate)}
                subValue={`${orgAnalyticsContainer.orderPlacedCount?.toString()} ${orgAnalyticsContainer.orderPlacedCount === 1 ? ' order placed' : ' orders placed'}`}
                tooltip='Orders Placed divided by Order Attempts.'
                highlight={true}
                className={highlightedTotalCardStyle}
                icon={orderPlacedIcon}
            />
        </SummaryContainer>
        <SummaryContainer>
            <StyledTotalCard
                title="Calls"
                value={orgAnalyticsContainer.callStatistics?.calls}
                tooltip='The total number of tracked sessions against this assistant.'
            />
            <StyledTotalCard title="Order Attempts"
                value={orgAnalyticsContainer.orderAttempts}
                tooltip='The total number of sessions where any items were added to the order.'
            />
        </SummaryContainer>
        <SummaryContainer>
            <StyledTotalCard
                title="Transfer Rate"
                value={formatPercentage(orgAnalyticsContainer.callTransferRate)}
                tooltip='Transfers rate per application'
            />
            <StyledTotalCard
                title="Immediate Transfer Rate"
                value={formatPercentage(orgAnalyticsContainer.immediateCallTransferRate)}
                subValue={`${orgAnalyticsContainer.immediateTransfers?.toString()} ${orgAnalyticsContainer.immediateTransfers === 1 ? ' immediate transfer' : ' immediate transfers'}`}
                tooltip='Immediate Transfers divided by Sessions.'
            />
            <StyledTotalCard
                title="Order Transfer Rate"
                value={formatPercentage(orgAnalyticsContainer.orderTransferRate)}
                subValue={`${orgAnalyticsContainer.transfers} ${orgAnalyticsContainer.immediateTransfers === 1 ? ' transfer' : ' transfers'}`}
                tooltip='Transfer rate during Order Attempts.'
            />
        </SummaryContainer>
        <div className={sessionsDiv}>
            {orgAnalyticsContainer.loadingSessionsOfAllApplications ?
                <div className={loadDiv}>
                    <Loader />
                </div> :
                <>
                    {orgAnalyticsContainer.failedOrderSessionIds.length === 0 ? <Header>No failed orders</Header> : null}
                    {orgAnalyticsContainer.failedOrderSessionIds.length ?
                        renderCollapsableSessionReview(orgAnalyticsContainer.failedOrderSessionIds, "Failed order sessions", expandFailedOrders, setExpandFailedOrders)
                        :
                        null
                    }
                    {orgAnalyticsContainer.ordersPlacedSessionIds.length ?
                        renderCollapsableSessionReview(orgAnalyticsContainer.ordersPlacedSessionIds, "Order placed sessions", expandPlacedOrders, setExpandPlacedOrder)
                        :
                        null
                    }
                    {orgAnalyticsContainer.noOrderSessionIds.length ?
                        renderCollapsableSessionReview(orgAnalyticsContainer.noOrderSessionIds, "No order sessions", expandNoOrders, setExpandNoOrders)
                        :
                        null
                    }
                    {orgAnalyticsContainer.orderTransferredSessionIds.length ?
                        renderCollapsableSessionReview(orgAnalyticsContainer.orderTransferredSessionIds, "Order sessions with transfers", expandOrderTransfers, setExpandOrderTransfers)
                        :
                        null
                    }
                </>
            }
        </div>
        <Spacer />
        <PageError errors={orgAnalyticsContainer.error ? [orgAnalyticsContainer.error] : null} />
        <CodeReviewModal />
        <CustomSessionReview />
    </>



};

const highlightedTotalCardStyle = css`
    border: 1px solid ${color_colors_ocean};
    box-shadow: 0px 4px 8px ${color_variants_blue_2};
`;

const StyledTotalCard = styled(TotalCard)`
    padding: 32px;
    height: auto;
    text-align: center;
    margin: 16px 16px 0px 16px;
`;

const Header = styled.h3`
    margin: 16px 32px;
    margin-top: 24px;
    font-size: 18px;
`;

const ClickableHeader = styled(Header)`
    cursor: pointer;
`;

const Spacer = styled.div`
    height: 100px;
`;

const sessionsDiv = css`
    position: relative;
`

const SummaryContainer = styled.div`
    display: flex;
    flex: 1;
    margin: 0 16px;
    padding-bottom: 16px;
`;

const loadDiv = css`
    margin-top: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
`

const FooterButton = styled(Button)`
    margin-left: auto;
`;

const SessionHeader = styled.h3`
    margin-top: 24px;
    margin-bottom: 16px;
    margin-left: 24px;
    margin-right: 24px;
    padding: 16px 16px;
    border: 1px solid ${color_shades_dark};
    cursor: pointer;
    border-radius: 12px 12px 12px 12px;
    display: flex;
    flex-direction: row;
    position: relative;
    &.loading {
        cursor: default;
        background-color: ${color_shades_light};
    }
`;

const SessionHeaderDate = styled.div`
    margin-top: 6px;
    margin-right: 8px;
    font-weight: lighter;
    min-width: 180px;
`;

const SessionLinkButton = styled(Button)`
    margin: 0;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    &:focus, 
    &:hover, 
    &:visited, 
    &:link, 
    &:active {
        text-decoration: none;
        color: inherit; 
    }
`;

const SessionLink = styled(Link)`
    margin-left: auto;
`;

export default OrganizationOrderingAnalyticsReport;
