import React, { useEffect, useState } from 'react';
import AnalyticsInterval from '../../../models/analytics/AnalyticsInterval';
import moment from 'moment';
import AnalyticsFilterState from '../../../models/analytics/AnalyticsFilterState';
import TimeIntervalSelectorHeader from '../../../components/analytics/TimeIntervalSelectorHeader';
import ScrollablePanelBody from '../../../components/structure/ScrollablePanelBody';
import DateRangeIntervalPicker from '../../../components/analytics/DateRangeIntervalPicker';
import { css } from '@emotion/css';
import { DATE_FORMAT } from '../../../models/analytics/AnalyticsDateFormat';
import qs from 'query-string';
import OrganizationAnalyticsPage from './OrganizationAnalyticsPage';
import PanelHeaderContainer from '../../../components/structure/Panels/PanelHeaderContainer';

interface OrgAnalyticsProps {
    history: any
}

const OrganizationAnalyticsBody: React.FC<OrgAnalyticsProps> = (props) => {

    const query = qs.parse(window.location.search);

    const [interval, setInterval] = useState<AnalyticsInterval>(query?.interval as string ?? 'past 30 days');
    const [startDate, setStartDate] = useState<string>(query?.startDate as string ?? moment().startOf('day').subtract(28, 'days').format(DATE_FORMAT));
    const [endDate, setEndDate] = useState<string>(query?.endDate as string ?? moment().endOf('day').add(2, 'days').format(DATE_FORMAT));
    const [isDatePickerExpanded, setIsDatePickerExpanded] = useState<boolean>(false);
    const [filter, setFilter] = useState<AnalyticsFilterState>({});

    const handleDateRangeChange = (startDate: string, endDate: string, interval: AnalyticsInterval) => {
        setStartDate(startDate);
        setEndDate(endDate);
        setInterval(interval);
        setIsDatePickerExpanded(false);
        setFilter({
            ...filter
        });

        props.history.push({
            search: `?startDate=${startDate}&endDate=${endDate}&interval=${interval}`
        });

    };

    const toggleDatePicker = () => {
        setIsDatePickerExpanded(!isDatePickerExpanded);
    };
    const currentFilter = {
        startDate: startDate,
        endDate: endDate,
    };
    return (
        <>
            <PanelHeaderContainer className={panelHeaderStyle}>
                <TimeIntervalSelectorHeader startDate={startDate} endDate={endDate} isExpanded={isDatePickerExpanded} onClick={toggleDatePicker} />
            </PanelHeaderContainer>
            <ScrollablePanelBody>
                {isDatePickerExpanded &&
                    <DateRangeIntervalPicker startDate={startDate}
                        endDate={endDate}
                        interval={interval}
                        onChange={handleDateRangeChange} />}
                <OrganizationAnalyticsPage history={props.history} filter={currentFilter} />
            </ScrollablePanelBody>
        </>
    )
}

const panelHeaderStyle = css`
    padding: 0 0px;
    button {
        margin-right: 0;
        margin-left: auto;
    }
    h2 {
        font-size: 18px;
        line-height: 24px;
    }
`

export default OrganizationAnalyticsBody;