import React from 'react';
import { css } from '@emotion/css';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
import TemplateFormSectionModel from '../../../models/templating/api/TemplateFormSectionModel';
import TextField from '../../../components/forms/TextField';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import { color_shades_dark, color_text_default } from '../../../constants/colors';
import AddMarkdownButton from './AddMarkdownButton';
import TemplateFormFieldModel from '../../../models/templating/api/TemplateFormFieldModel';
import TextFieldEditor from './TextFieldEditor';
import MarkdownEditorModal from './MarkdownEditorModal';
import { DropResult, ResponderProvided, DragDropContext, Droppable, Draggable, DraggableProvidedDragHandleProps, DraggableProvided } from 'react-beautiful-dnd';
import _ from 'lodash';
import LargeOverflowMenu from '../../../components/general/LargeOverflowMenu';
import Button from '../../../components/general/Button';
import { reorder } from '../../../models/extensions';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import DeleteConfirmation from '../../featureContent/components/DeleteConfirmation';
import OptionsFieldEditor from './OptionsFieldEditor';
import Loader from '../../../components/general/Loader';
import FieldUploadFieldEditor from './FileUploadFieldEditor';
const menuIcon = require('../../../content/images/menu-item-icons/circle-menu.svg');
const editIcon = require('../../../content/images/menu-item-icons/circle-edit.svg');
const deleteIcon = require('../../../content/images/menu-item-icons/bin.svg');
const upIcon = require('../../../content/images/menu-item-icons/navigation-up.svg');
const downIcon = require('../../../content/images/menu-item-icons/navigation-down.svg');

interface TemplateSectionProps {
    templateConfiguration: TemplateConfigurationModel
    section: TemplateFormSectionModel
    onChange: (templateConfiguration: TemplateConfigurationModel) => void
    onDelete: (section: TemplateFormSectionModel) => void
}

interface TemplateSectionState {
    isMarkdownOpen: boolean
    isDeleteConfirmationOpen: boolean
    isEditingTitle: boolean
}
class TemplateSectionEditor extends React.Component<TemplateSectionProps, TemplateSectionState>{

    constructor(props) {
        super(props);
        this.state = {
            isMarkdownOpen: false,
            isDeleteConfirmationOpen: false,
            isEditingTitle: false
        }
    }
    handleToggleMarkdown() {
        this.setState({
            ...this.state,
            isMarkdownOpen: !this.state.isMarkdownOpen
        })
    }
    handleToggleTitle() {
        this.setState({
            ...this.state,
            isEditingTitle: !this.state.isEditingTitle
        })
    }
    handleToggleDelete() {
        this.setState({
            ...this.state,
            isDeleteConfirmationOpen: !this.state.isDeleteConfirmationOpen
        })
    }
    handleMarkdownChange = (markdown: string) => {
        const template = this.props.templateConfiguration;
        const section = template?.templateForm?.templateFormSections?.find(s => s == this.props.section)

        section.instructionsMarkdown = markdown;
        this.props.onChange(template);
        this.setState({
            ...this.state,
            isMarkdownOpen: false
        })
    }
    handleTitleChange = (e) => {
        const template = this.props.templateConfiguration;
        const section = template?.templateForm?.templateFormSections?.find(s => s == this.props.section);

        section.title = e.target.value;

        this.props.onChange(template);
    }
    handleFieldChange = (field: TemplateFormFieldModel) => {
        const template = this.props.templateConfiguration;
        const section = this.props.section
        if (!section)
            return;
        const fieldIndex = section?.templateFormFields?.findIndex(f => f.id == field.id);
        if (fieldIndex <= -1) {
            return;
        }

        section.templateFormFields[fieldIndex] = field;

        this.props.onChange(template);
    }
    handleMoveFieldUp = (field: TemplateFormFieldModel) => {
        const template = this.props.templateConfiguration;
        const section = template?.templateForm?.templateFormSections?.find(s => s == this.props.section)
        const fields = _.sortBy(section?.templateFormFields ?? [], s => s.priority)
        const index = fields.indexOf(field);

        if (index > 0) {
            var sortedFields = reorder(fields, index, index - 1).map((f, i) => ({ ...f, priority: i }));
            section.templateFormFields = sortedFields;

            this.props.onChange(template);

        }
    }
    handleMoveFieldDown = (field: TemplateFormFieldModel) => {
        const template = this.props.templateConfiguration;
        const section = template?.templateForm?.templateFormSections?.find(s => s == this.props.section)
        const fields = _.sortBy(section?.templateFormFields ?? [], s => s.priority)
        const index = fields.indexOf(field);

        if (index < fields.length - 1) {
            var sortedFields = reorder(fields, index, index + 1).map((f, i) => ({ ...f, priority: i }));
            section.templateFormFields = sortedFields;

            this.props.onChange(template);

        }
    }
    handleMoveFieldToSection = (field: TemplateFormFieldModel, sectionId: string) => {
        const template = this.props.templateConfiguration;
        const currentSection = template?.templateForm?.templateFormSections?.find(s => s == this.props.section)
        const newSection = template?.templateForm?.templateFormSections?.find(s => s.id == sectionId);
        if (!newSection) return;


        _.remove(currentSection.templateFormFields, f => f.id == field.id);
        newSection.templateFormFields.push(field);

        this.props.onChange(template);

    }
    handleDeleteConfirm() {
        this.props.onDelete(this.props.section);
        this.handleToggleDelete();
    }
    handleDragEnd = (result: DropResult, provider: ResponderProvided) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const template = this.props.templateConfiguration;
        const section = template?.templateForm?.templateFormSections?.find(s => s == this.props.section)
        const fields = _.sortBy(section?.templateFormFields ?? [], s => s.priority)


        var sortedFields = reorder(fields, result.source.index, result.destination.index).map((f, i) => ({ ...f, priority: i }));
        section.templateFormFields = sortedFields;

        this.props.onChange(template);

    }
    handleDeleteField = (field: TemplateFormFieldModel) => {

        const template = this.props.templateConfiguration;
        const section = template?.templateForm?.templateFormSections?.find(s => s == this.props.section)
        const fields = _.sortBy(section?.templateFormFields ?? [], s => s.priority)
        _.remove(fields, f => f.id == field.id);

        section.templateFormFields = fields;

        this.props.onChange(template);
    }
    getOptions() {
        const options = [
            { label: 'Delete', icon: deleteIcon, hoverIcon: deleteIcon, isDestructive: true },
            { label: 'Move Up', icon: upIcon, hoverIcon: upIcon },
            { label: 'Move Down', icon: downIcon, hoverIcon: downIcon }
        ];
        return options;
    }
    handleOptionSelected(optionName: string) {
        const template = this.props.templateConfiguration;
        var sections = template?.templateForm?.templateFormSections;
        const index = sections.indexOf(this.props.section);
        if (optionName == 'Move Up') {
            if (index > 0) {
                sections = reorder(sections, index, index - 1);
                sections.forEach((s, i) => {
                    s.priority = i;
                });
                template.templateForm.templateFormSections = sections;
                this.props.onChange(template);
            }
        }
        if (optionName == 'Move Down') {
            if (index < sections.length - 1) {
                sections = reorder(sections, index, index + 1);
                sections.forEach((s, i) => {
                    s.priority = i;
                });
                template.templateForm.templateFormSections = sections;
                this.props.onChange(template);
            }
        }
        if (optionName == 'Delete') {
            this.handleToggleDelete();
        }
    }
    handleEnterBlur(e) {
        if (e.charCode == 13) {
            e.target.blur();
        }
    }

    renderField = (f: TemplateFormFieldModel, provided: DraggableProvided) => {
        switch (f.fieldType) {
            case "ShortTextField":
            case "NumberTextField":
            case "DateTextField":
            case "LongTextField":
            case "SSMLTextField": return <TextFieldEditor key={f.id}
                field={f}
                sections={this.props.templateConfiguration?.templateForm?.templateFormSections ?? []}
                onChange={this.handleFieldChange.bind(this)}
                onDelete={this.handleDeleteField.bind(this)}
                onMoveDown={this.handleMoveFieldDown.bind(this)}
                onMoveUp={this.handleMoveFieldUp.bind(this)}
                onMoveToSection={this.handleMoveFieldToSection.bind(this)}
                draggable={provided} />

            case "RadioGroupField":
            case "CheckboxGroupField":
            case "SelectField": return <OptionsFieldEditor key={f.id}
                field={f}
                sections={this.props.templateConfiguration?.templateForm?.templateFormSections ?? []}
                onChange={this.handleFieldChange.bind(this)}
                onDelete={this.handleDeleteField.bind(this)}
                onMoveDown={this.handleMoveFieldDown.bind(this)}
                onMoveUp={this.handleMoveFieldUp.bind(this)}
                onMoveToSection={this.handleMoveFieldToSection.bind(this)}
                draggable={provided} />

            case "FileUploadField": return <FieldUploadFieldEditor key={f.id}
                field={f}
                sections={this.props.templateConfiguration?.templateForm?.templateFormSections ?? []}
                onChange={this.handleFieldChange.bind(this)}
                onDelete={this.handleDeleteField.bind(this)}
                onMoveDown={this.handleMoveFieldDown.bind(this)}
                onMoveUp={this.handleMoveFieldUp.bind(this)}
                onMoveToSection={this.handleMoveFieldToSection.bind(this)}
                draggable={provided} />
        }

        return null;
    }

    render() {
        if(!this.props.section) return <Loader/>

        return (<div className={containerStyle}>
            <div className="title-wrapper">
                {this.state.isEditingTitle
                    ? <TextField name="section.title"
                        value={this.props.section.title}
                        onChange={this.handleTitleChange.bind(this)}
                        placeholder="Page Title"
                        className="section-title-field"
                        onBlur={this.handleToggleTitle.bind(this)}
                        onKeyPress={this.handleEnterBlur.bind(this)}
                        autoFocus />
                    : <h4>{this.props.section.title}</h4>}
                {!this.state.isEditingTitle && <Button type="button" themes={['icon']} className="section-button" icon={editIcon} onClick={this.handleToggleTitle.bind(this)} />}
                <div className="section-button">
                    <LargeOverflowMenu isActive={false} onOptionSelected={this.handleOptionSelected.bind(this)} options={this.getOptions()} icon={menuIcon} />
                </div>
            </div>
            <p className="detail-text">Set up your module and configure the fields</p>
            <HorizontalSeparator />
            <AddMarkdownButton onClick={this.handleToggleMarkdown.bind(this)} hasValue={this.props.section?.instructionsMarkdown != null && this.props.section.instructionsMarkdown != ''} />
            {(!this.props.section.templateFormFields || this.props.section.templateFormFields.length == 0)
                ? <p className="empty-label">Select assets from the right to add to this page. You can configure them here once they’ve been added</p>
                : <DragDropContext onDragEnd={this.handleDragEnd.bind(this)}>
                    <Droppable droppableId={"fields"} type="TEMPLATE_FIELD">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {
                                    this.props.section.templateFormFields?.map((f, i) => (
                                        <Draggable draggableId={f?.id} index={i} key={f.id}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}>
                                                    {this.renderField(f, provided)}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

            }
            {this.state.isMarkdownOpen
                && <MarkdownEditorModal
                    contextTitle={this.props.section.title ?? "Page"}
                    markdown={this.props.section.instructionsMarkdown ?? ''}
                    onSave={this.handleMarkdownChange.bind(this)}
                    onClose={this.handleToggleMarkdown.bind(this)} />}
            {this.state.isDeleteConfirmationOpen &&
                <ConfirmationDialog title={`Delete ${this.props.section.title ?? "Page Instructions"}`}
                    deleteText="Yes, Delete"
                    isLoading={false}
                    onClose={this.handleToggleDelete.bind(this)}
                    onConfirm={(this.handleDeleteConfirm.bind(this))}>
                    <DeleteConfirmation itemLabel={`${this.props.section.title}?`} />
                </ConfirmationDialog>}
        </div>)
    }
}

const containerStyle = css`
    padding: 16px 32px;
    margin-bottom: 64px;
    .title-wrapper {
        display: flex;
        align-items: center;
        h4 {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            color: ${color_text_default};
            flex: 1;
        }
        .section-title-field {
            margin: 0;
        }
        .section-button {
            margin-right: 0;
            margin-left: 16px;
            cursor: pointer;

        }
    }

    .detail-text {
        margin: 16px 0 24px 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }

    .empty-label {
        text-align: center;
        border: 1px solid ${color_shades_dark};
        border-radius: 8px;
        padding: 32px;
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        margin: 32px 0;
    }
`;

export default TemplateSectionEditor;