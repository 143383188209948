import React from 'react';
import moment from 'moment';
import { TabPanel } from 'react-tabs';
import AnalyticsInterval from '../../../models/analytics/AnalyticsInterval';
import { MeasurementType } from '../../../components/analytics/MeasurementTypePicker';
import ScrollablePanelBody from '../../../components/structure/ScrollablePanelBody';
import TabbedLayout from '../../../components/structure/TabbedLayout';
import { DATE_FORMAT } from '../../../models/analytics/AnalyticsDateFormat';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';
import OrganizationOrderingAnalyticsReport from './OrganizationOrderingAnalyticsReport';
import OrganizationApplicationPerformanceReport from './OrganizationApplicationPerformanceAnalyticsReport';

interface OrganizationAnalyticsPageProps {
  filter: AnalyticsRequestFilter
  history?: any
}
class OrganizationAnalyticsPage extends React.Component<OrganizationAnalyticsPageProps> {
  handleTabSelection(index: number, last: number) {
    if (index == last) return;
    const urlStart = "/v/orgAnalytics/";

    if (index == 0) {
      this.props.history.push(urlStart)
    }
    if (index == 1) {
      this.props.history.push(urlStart + "applicationsPerformance")
    }
  }
  getSelectedIndex() {
    if (location.href.indexOf("applicationsPerformance") > -1) {
      return 1;
    }
    if (location.href.indexOf("report") > -1) {
      return 2;
    }

    return 0;
  }

  getInnerInterval(startDate: string, endDate: string): AnalyticsInterval {
    // rule of 3s.
    // if we are under 3 days, use hours
    // if we are under 3 months, use days
    // if we are under 3 years, use months
    // use years

    // get hours between dates
    const hoursBetween = moment(endDate, DATE_FORMAT).diff(moment(startDate, DATE_FORMAT), 'hours');
    if (hoursBetween <= (24 * 3)) {
      return 'hour';
    }
    if (hoursBetween <= (24 * 3 * 30)) {
      return "day"
    }
    if (hoursBetween <= (24 * 3 * 365)) {
      return "month"
    }
    return "year"
  }

  handleMeasurementTypeChange(type: MeasurementType) {
    this.setState({
      ...this.state,
      measurementType: type
    })
  }

  render() {
    return (
      <ScrollablePanelBody>
        <TabbedLayout tabs={["Ordering", "Application Performance"]} top
          selectedIndex={this.getSelectedIndex()}
          onTabSelected={this.handleTabSelection.bind(this)}>
          <TabPanel>
            <OrganizationOrderingAnalyticsReport filter={this.props.filter} />
          </TabPanel>
          <TabPanel>
            <OrganizationApplicationPerformanceReport filter={this.props.filter} />
          </TabPanel>
        </TabbedLayout>
      </ScrollablePanelBody>
    )
  }
}

export default OrganizationAnalyticsPage;