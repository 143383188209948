import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import ScrollablePanelBody from '../../../components/structure/ScrollablePanelBody';
import Loader from '../../../components/general/Loader';
import UpdateAppliedAppTemplateForm from './UpdateAppliedAppTemplateForm';
import AppliedAppTemplateDetailsForm from './AppliedAppTemplateDetailsForm';
import AppliedAppTemplateReviewForm from './AppliedAppTemplateReviewForm';
import PanelContainer from '../../../components/structure/Panels/PanelContainer';
import CollapsingPanel from '../../../components/structure/Panels/CollapsingPanel';
import CollapsedMenuPanel from '../../../components/structure/CollapsedMenuPanel';
import ExpandedMenuPanel from '../../../components/structure/ExpandedMenuPanel';
import AppliedAppTemplateSectionMenu from './AppliedAppTemplateSectionMenu';
import { color_variants_main_bg, color_shades_dark, color_shades_lighter } from '../../../constants/colors';
import FullPagePanel from '../../../components/structure/Panels/FullPagePanel';
import HeaderBar from '../../../components/structure/HeaderBar';
import BackButton from '../../../components/general/BackButton';
import TemplateFormSectionModel from '../../../models/templating/api/TemplateFormSectionModel';
import ApplicationTemplateMenuSelector from '../../applicationTemplateMenuSelector';
import HeaderTitleView from '../../../components/structure/HeaderTitleView';
import { BreadcrumbItem } from '../../../components/general/Breadcrumb';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
import PanelHeaderContainer from '../../../components/structure/Panels/PanelHeaderContainer';
import AppliedTemplateStatusIndicator from './AppliedTemplateStatusIndicator';
import PageError from '../../../components/general/PageError';
import TemplateFormFieldValueModel from '../../../models/templating/api/TemplateFormFieldValueModel';
import AppliedApplicationTemplateModel from '../../../models/templating/api/AppliedApplicationTemplateModel';
import ApplicationEnvironmentsContainer from '../../../state/containers/ApplicationEnvironmentsContainer';

interface AppliedTemplateManagerContentProps {
    applicationId: string
    appliedAppTemplateId?: string
    templateConfigurationId?: string
    appContainer: ApplicationContainer
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    orgContainer: OrganizationContainer
    templateConfigContainer: TemplateConfigurationContainer
    applicationEnvironmentsContainer: ApplicationEnvironmentsContainer
    history: any
}

const AppliedTemplateManagerContent: React.FC<AppliedTemplateManagerContentProps> = (props) => {
    const [isLeftExpanded, setIsLeftExpanded] = useState<boolean>(localStorage.getItem("contentMenu") == "true");
    const [isFinishSelected, setIsFinishSelected] = useState<boolean>(false);
    const [isStartSelected, setIsStartSelected] = useState<boolean>(true);
    const [currentSection, setCurrentSection] = useState<TemplateFormSectionModel>(null);
    const [isNew, setIsNew] = useState<boolean>(props.templateConfigurationId && !props.appliedAppTemplateId);
    const [areNoTemplateFormSections, setAreNoTemplateFormSections] = useState<boolean>(false);

    useEffect(() => {
        props.appContainer.loadCurrentAppById(props.applicationId);
    }, []);

    const getCurrentAppliedTemplate = () => {
        return props.appliedAppTemplateContainer.state.appliedTemplates?.find(at => at.id == props.appliedAppTemplateId);
    };

    const getCurrentSectionIndex = () => {
        const appliedTemplateSections = getCurrentAppliedTemplate()?.templateForm?.templateFormSections ?? [];
        const section = appliedTemplateSections.find(s => (currentSection?.id?.length && s.id == currentSection?.id)
            || (currentSection?.dynamicFormFields?.length && s.name === currentSection?.name));
        return appliedTemplateSections.indexOf(section);
    };

    const getCurrentTemplateConfig = () => {
        const templateConfig = props.appliedAppTemplateId
            ? getCurrentAppliedTemplate()?.templateForm?.templateConfiguration
            : props.templateConfigContainer.state.availableTemplates?.find(t => t.id == props.templateConfigurationId);
        return templateConfig;
    };

    const handleToggleExpandLeft = (e, expanded: boolean) => {
        setIsLeftExpanded(expanded);
        localStorage.setItem("contentMenu", expanded?.toString());
        e?.stopPropagation();
    };

    const handleFinishSelected = () => {
        setIsFinishSelected(true);
        setIsStartSelected(false);
        setCurrentSection(null);
    };

    const handleStartSelected = () => {
        setIsFinishSelected(false);
        setIsStartSelected(true);
        setCurrentSection(null);
    };

    const handleSectionSelected = (section: TemplateFormSectionModel) => {
        setCurrentSection(section);
        setIsFinishSelected(false);
        setIsStartSelected(false);
    };

    const handleNextSection = () => {
        const currentIndex = getCurrentSectionIndex();
        const sections = getCurrentAppliedTemplate()?.templateForm?.templateFormSections;
        if (sections.length > 0) {
            if (currentIndex == (sections.length - 1)) {
                setIsFinishSelected(true);
                setIsStartSelected(false);
                setCurrentSection(null);
            } else {
                setCurrentSection(sections[currentIndex + 1]);
                setIsFinishSelected(false);
                setIsStartSelected(false);
            }
        } else {
            setIsFinishSelected(true);
            setIsStartSelected(false);
            setCurrentSection(null);
            setAreNoTemplateFormSections(true);
        }
    }

    const handlePreviousSection = async () => {
        let currentDynamicSectionName: string;
        if (currentSection?.name) {
            currentDynamicSectionName = currentSection.name
        }
        let currentIndex = getCurrentSectionIndex();
        if (areNoTemplateFormSections) {
            currentIndex = 0;
            setIsFinishSelected(false);
            setIsStartSelected(true);
            setCurrentSection(null);
            setAreNoTemplateFormSections(false);
            return;
        }
        if (currentIndex == 0) {
            setIsFinishSelected(false);
            setIsStartSelected(true);
            setCurrentSection(null);
            return;
        } else if (currentIndex === -1 && isFinishSelected) {
            currentIndex = getCurrentAppliedTemplate()?.templateForm.templateFormSections.length
        }
        const prevSection = getCurrentAppliedTemplate()?.templateForm.templateFormSections[currentIndex - 1];
        if (prevSection) {
            setCurrentSection(prevSection);
        } else {
            setCurrentSection(null);
        }
        if (currentDynamicSectionName) {
            await props.appliedAppTemplateContainer.removeDynamicFormSection(props.appliedAppTemplateId, currentDynamicSectionName);
        }
        setAreNoTemplateFormSections(false);
    };

    const handleClose = () => {
        if (props.history.length > 1) props.history.goBack();
        else props.history.push(`/v/apps/${props.applicationId}/templates/`);
    };

    const getBreadCrumbItems = () => {
        const app = props.appContainer.state.currentApp;
        const crumbs: BreadcrumbItem[] = [{
            id: 'org',
            title: props.orgContainer.state.currentOrganization?.name,
            link: '/'
        }, {
            id: 'app',
            title: app?.name,
            link: `/v/apps/${app?.id}`
        }]

        return crumbs;
    };

    const handleInsertDynamicFormSection = async (dynamicSection: TemplateFormSectionModel) => {
        const newSection = await props.appliedAppTemplateContainer.insertDynamicFormSection(getCurrentAppliedTemplate().id, dynamicSection, currentSection)
        setIsFinishSelected(false);
        setIsStartSelected(false);
        setCurrentSection(newSection);
    };

    const getPageCount = () => {
        return ((getCurrentAppliedTemplate()?.templateForm ?? getCurrentTemplateConfig()?.templateForm)?.templateFormSections?.length ?? 0) + 2;
    };

    const getCurrentTemplateForm = () => {
        return isNew
            ? getCurrentTemplateConfig()?.templateForm
            : getCurrentAppliedTemplate()?.templateForm;
    };

    const getSections = () => {
        return getCurrentTemplateForm()?.templateFormSections?.map(s => (
            {
                section: s,
                isComplete: props.appliedAppTemplateContainer.getIsSectionOfCurrentComplete(getCurrentAppliedTemplate(), s),
                isDisabled: !props.appliedAppTemplateId
            }
        ));
    };

    const getCurrentPageNumber = () => {
        if (isStartSelected) return 1;
        if (isFinishSelected) return getPageCount();
        return getCurrentSectionIndex() + 2;
    };

    const getDefaultTemplateValues = (appliedAppTemplate: AppliedApplicationTemplateModel) => {
        const templateValues = [];
        appliedAppTemplate?.templateForm?.templateFormSections?.forEach(formSection => {
            formSection?.templateFormFields?.forEach(formField => {
                if (formField?.defaultValue) {
                    const fieldValue: TemplateFormFieldValueModel = {
                        value: formField?.defaultValue,
                        fieldType: formField?.fieldType,
                        tip: formField?.tip,
                        title: formField?.title,
                        label: formField?.label,
                        defaultValue: formField?.defaultValue,
                        associatedVariable: formField?.associatedVariable,
                        placeholder: formField?.placeholder,
                        templateFormFieldId: formField?.id
                    };
                    templateValues.push(fieldValue);
                }
            })
        })
        return templateValues;
    };

    const renderMenu = () => {
        return (
            <>
                <PanelHeaderContainer>
                    <div className="page-indicator-container">
                        <p>Step {getCurrentPageNumber()} of {getPageCount()}</p>
                    </div>
                </PanelHeaderContainer>
                <ScrollablePanelBody className={panelBodyStyle}>
                    <AppliedAppTemplateSectionMenu
                        history={props.history}
                        applicationId={props.applicationId}
                        appContainer={props.appContainer}
                        appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                        activeSection={currentSection ? { section: currentSection, isComplete: props.appliedAppTemplateContainer.getIsSectionOfCurrentComplete(getCurrentAppliedTemplate(), currentSection) } : null}
                        sections={getSections()}
                        isFinishSelected={isFinishSelected}
                        isStartSelected={isStartSelected}
                        isNew={isNew}
                        onFinishSelect={handleFinishSelected}
                        onStartSelect={handleStartSelected}
                        onSectionSelect={handleSectionSelected}
                        templateConfigContainer={props.templateConfigContainer}
                        orgContainer={props.orgContainer}
                        appliedAppTemplateId={props.appliedAppTemplateId}
                    />
                </ScrollablePanelBody>
            </>);
    }

    const renderRightBody = () => {
        const { isLoading } = props.appliedAppTemplateContainer.state;
        const currentAppliedTemplate = getCurrentAppliedTemplate();
        const defaultValues = getDefaultTemplateValues(currentAppliedTemplate);
        if (currentAppliedTemplate != null && (currentAppliedTemplate?.templateFormFieldValues == null || currentAppliedTemplate?.templateFormFieldValues?.length === 0))
            currentAppliedTemplate.templateFormFieldValues = defaultValues;

        if (isLoading)
            return <Loader />

        if (currentSection)
            return <UpdateAppliedAppTemplateForm
                history={props.history}
                applicationId={props.applicationId}
                appContainer={props.appContainer}
                appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                appliedAppValues={currentAppliedTemplate?.templateFormFieldValues}
                appliedAppTemplate={currentAppliedTemplate}
                templateSection={currentSection}
                insertDynamicFormSection={handleInsertDynamicFormSection}
                onNext={handleNextSection}
                onPrevious={handlePreviousSection} />

        if (isStartSelected)
            return <AppliedAppTemplateDetailsForm
                history={props.history}
                applicationId={props.applicationId}
                appContainer={props.appContainer}
                appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                templateConfiguration={getCurrentTemplateConfig()}
                templateForm={getCurrentAppliedTemplate()?.templateForm}
                isNew={isNew}
                onNext={handleNextSection.bind(this)}
                appliedAppTemplate={getCurrentAppliedTemplate()} />

        if (isFinishSelected)
            return <AppliedAppTemplateReviewForm
                history={props.history}
                applicationId={props.applicationId}
                appContainer={props.appContainer}
                appliedAppTemplate={currentAppliedTemplate}
                templateConfiguration={getCurrentTemplateConfig()}
                appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                appliedAppValues={currentAppliedTemplate?.templateFormFieldValues ?? []}
                onPrevious={handlePreviousSection.bind(this)}
                appliedAppTemplateId={currentAppliedTemplate?.id}
                incompleteSections={props.appliedAppTemplateContainer.getIncompleteSections(getCurrentAppliedTemplate())}
                applicationEnvironmentsContainer={props.applicationEnvironmentsContainer}

            />

        return null;
    }

    return (
        <FullPagePanel className={containerStyle}>
            <HeaderBar>
                <BackButton onClick={handleClose.bind(this)} />
                <ApplicationTemplateMenuSelector applicationId={props.applicationId} history={props.history} templateConfiguration={getCurrentTemplateConfig()} />
                <HeaderTitleView text={getCurrentTemplateConfig()?.name} breadcrumb={getBreadCrumbItems()} app={props.appContainer.state.currentApp} />
                <AppliedTemplateStatusIndicator status={props.appliedAppTemplateContainer.getCurrentStatus(getCurrentAppliedTemplate())} />
            </HeaderBar>
            <PanelContainer className={containerStyle}>
                <div className={`${menuStyle} ${(isLeftExpanded ? "expanded" : "collapsed")}`}>
                    <div className={"fixed-wrapper"}>
                        <CollapsingPanel isCollapsed={!isLeftExpanded}
                            collapsedView={<CollapsedMenuPanel
                                onExpand={(e) => handleToggleExpandLeft(e, true)}
                                expandedViewRender={renderMenu} />}
                            expandedView={<ExpandedMenuPanel
                                onCollapse={(e) => handleToggleExpandLeft(e, false)}
                                expandedViewRender={renderMenu} />} />
                    </div>
                </div>
                <PanelContainer className={formBodyStyle}>
                    <div className="form-wrapper">
                        {renderRightBody()}
                    </div>
                </PanelContainer>
            </PanelContainer>
            <PageError errors={props.appliedAppTemplateContainer.state.errors} onClear={() => props.appliedAppTemplateContainer.clearErrors()} />
        </FullPagePanel>

    );
}

const containerStyle = css`
    background: ${color_variants_main_bg};
    .form-wrapper {
        flex: 1;
        min-width: 600px;
    }
    .empty-indicator {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .page-indicator-container {
        background: ${color_shades_lighter};
        border: 1px solid ${color_shades_dark};
        box-sizing: border-box;
        border-radius: 4px;
        margin: 0 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 16px;
    }
`;

const formBodyStyle = css`
    border-right: 1px solid ${color_shades_dark};
    box-sizing: border-box;
    .scrollable-body {       
        padding-bottom: 56px;
    }
`;

const panelBodyStyle = css`
    padding-top: 0;
    min-height: 100%;
`;

const menuStyle = css`
z-index: 2;
bottom: 0;
position: relative;
&.collapsed {
    width: 80px;
    .fixed-wrapper {
        width: 80px;
    }
}
&.expanded {
    width: 288px; 
    .fixed-wrapper {
        width: 288px;
    }
}
.fixed-wrapper {
    position: fixed;
    bottom: 0;
    height: calc(100vh - 80px);
    background: white;
    border-right: 1px solid ${color_shades_dark};
    box-sizing: border-box;
}
`;

export default AppliedTemplateManagerContent;