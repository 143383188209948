import React from 'react';
import { css } from '@emotion/css';
import { color_text_default, color_text_light } from '../../../constants/colors';

interface PanelTitleProps {
    title: string
    detail: string
}

const PanelTitle: React.SFC<PanelTitleProps> = (props) => (
    <div className={containerStyle}>
        <div className="title-container">
            <h3>{props.title}</h3>
            <div className="buttons">{props.children}</div>
        </div>
        <p>{props.detail}</p>
    </div>
)

const containerStyle = css`
    padding: 16px 32px;
    .title-container {
        display: flex;
        align-items: center;
        .buttons {
            margin-right: 0;
            margin-left: auto;
            display: flex;
            align-items: center;
            button {
                margin: 0 16px;
            }
        }
    }
    h3 {
        font-size: 24px;
        line-height: 32px;
        color: ${color_text_default};
        font-style: normal;
        font-weight: normal;
    }
    p {
        font-size: 14px;
        line-height: 20px;
        color: ${color_text_light};
        margin: 16px 0;
    }
`;

export default PanelTitle;