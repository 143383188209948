import React, { useEffect, useState } from "react";
import LanguageModel from "../../../models/features/api/LanguageModel";
import TextField from "../../../components/forms/TextField";
import Button from "../../../components/general/Button";
import styled from "@emotion/styled";
import { color_shades_light } from "../../../constants/colors";
import HorizontalSeparator from "../../../components/structure/HorizontalSeparator";
import EditableNlpEntity from "../../../models/nlpEntity/EditableNlpEntity";
import { ArrayHelpers, FieldArray } from "formik";
import NlpEntityValueModel from "../../../models/nlpEntity/api/NlpEntityValueModel";

const closeIcon = require("../../../content/images/close_icon.svg");
const addIcon = require("../../../content/images/add-circle-dark.svg");

export interface NlpEntityValueCardProps {
    languages?: LanguageModel[];
    nlpEntity: EditableNlpEntity;
    nlpValue: NlpEntityValueModel;
    nlpValueIndex: number;
    formPrefix: string;
    formIndex: number;
    validateField;
    handleChange;
    handleBlur;
    setFieldValue;
    handleReset;
    handleSubmit;
    dirty: boolean;
    isValid: boolean;
    status?: any;
}

const NlpEntityValueCard = (props: NlpEntityValueCardProps) => {
    const [newSynonymValue, setNewSynonymValue] = useState("");

    const nlpEntityValue = props.nlpValue;
    const nlpEntityValueName: string = props.nlpValue?.name;
    const nlpEntityValueSynonyms = props.nlpValue?.synonyms;

    const disableFields: boolean =
        !props.nlpEntity.isEditable || props.nlpEntity.isDeleted; //|| props.isSubmitting || props.isValidating

    const isModifiedField = `${props.formPrefix}.${props.formIndex}.isModified`;

    const cardPrefix = `${props.formPrefix}.${props.formIndex}.values.${props.nlpValueIndex}`;
    const nameFieldName: string = `${cardPrefix}.name`;
    const patternFieldName: string = `${cardPrefix}.pattern`;
    const synonymsFieldName: string = `${cardPrefix}.synonyms`;

    useEffect(
        function resetNlpValueSynonymOnEntitySelect() {
            setNewSynonymValue("");
        },
        [props.formIndex]
    );

    const handleNewSynonymKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
        if (disableFields) return;

        if (event.key === "Escape") {
            // Clear new synonym
            setNewSynonymValue("");
        } else if (event.key === "Enter") {
            // Add new synonym
            handleAddSynonym();
        }
        event.stopPropagation();
    };

    const handleDeleteSynonym = (
        index: number,
        arrayHelpers: ArrayHelpers
    ) => {
        arrayHelpers.remove(index);
        props.setFieldValue(isModifiedField, true);
    };

    const handleAddNewSynonymChange = (event) => {
        const synonym: string = event.target.value;
        setNewSynonymValue(synonym);
    };

    const handleAddSynonym = () => {
        const error = validateNlpValueSynonym(newSynonymValue);
        if (error) {
            return;
        }
        const index: number = props.nlpValue?.synonyms?.length ?? 0;
        props.setFieldValue(`${synonymsFieldName}.${index}`, newSynonymValue);
        props.setFieldValue(isModifiedField, true);
        setNewSynonymValue("");
    };

    const validateNlpValueSynonym = (value: string) => {
        let error;
        if (!value || value.trim() == "") {
            error = "List Value Synonym cannot be empty";
        }
        return error;
    };

    const handleChange = (fieldName, event) => {
        props.setFieldValue(isModifiedField, true);
        props.handleChange(event);
    };

    const renderListTypeEdits = () => {
        return (
            <>
                <TextField
                    name={nameFieldName}
                    disabled={disableFields}
                    required
                    placeholder="Name"
                    defaultValue={nlpEntityValueName}
                    label="Name"
                    onChange={(e) => handleChange(nameFieldName, e)}
                    onBlur={props.handleBlur}
                    autoFocus={true}
                    key={props.nlpValueIndex}
                />

                <SynonymsHeader> SYNONYMS </SynonymsHeader>
                {nlpEntityValueSynonyms && (
                    <SynonymsHolder>
                        <FieldArray
                            validateOnChange={false}
                            name={synonymsFieldName}
                            render={(arrayHelpers) =>
                                nlpEntityValueSynonyms?.map((synonym, synIndex) => (
                                    <SynonymChip
                                        key={`display-${synonymsFieldName}.${synIndex}`}>
                                        <span>{synonym}</span>
                                        {
                                            disableFields ?
                                                null
                                                :
                                                <img onClick={() => handleDeleteSynonym(synIndex, arrayHelpers)} src={closeIcon} />
                                        }
                                    </SynonymChip>
                                ))
                            }
                        />
                    </SynonymsHolder>
                )}

                {!disableFields && (
                    <>
                        <div
                            className="new-field-container"
                            key={`${synonymsFieldName}.addNewContainer`}
                            onKeyUp={handleNewSynonymKeyUp}
                        >
                            <TextField
                                disabled={disableFields}
                                placeholder="What is another name for this?"
                                value={newSynonymValue}
                                onChange={handleAddNewSynonymChange}
                                onBlur={props.handleBlur}
                                useForm={false}
                                autoFocus={!nlpEntityValue.isAdded}
                            />
                            <AddSynonymButton
                                type="button"
                                themes={["icon", "end-tight"]}
                                icon={addIcon}
                                disabled={!newSynonymValue || disableFields}
                                onClick={handleAddSynonym}
                            />
                        </div>
                    </>
                )}

                <HorizontalSeparator />
            </>
        );
    };

    const renderRegexTypeEdits = () => {
        return (
            <>
                <div key={`${cardPrefix}.regex`}>
                    <TextField
                        name={patternFieldName}
                        disabled={disableFields}
                        required
                        placeholder="NLP Entity Regex Pattern"
                        defaultValue={nlpEntityValue.pattern}
                        label="Regex"
                        onChange={(e) => handleChange(patternFieldName, e)}
                        onBlur={props.handleBlur}
                        autoFocus={true}
                    />
                </div>
            </>
        );
    };

    const renderNativeTypeEdits = () => {
        return <></>;
    };

    return (
        <CardContainer>
            <HorizontalSeparator />
            <fieldset>
                {props.nlpEntity.type === "Regex" && renderRegexTypeEdits()}
                {props.nlpEntity.type === "Native" && renderNativeTypeEdits()}
                {props.nlpEntity.type === "List" && renderListTypeEdits()}
            </fieldset>
        </CardContainer>
    );
};

export default NlpEntityValueCard;

const SynonymChip = styled.div`
    height: 42px;
    padding: 0 8px 0 16px;
    margin: 0 8px 8px 0;
    display: flex;
    flex-direction: row;
    background: ${color_shades_light};
    border-radius: 32px;
    line-height: 40px;
    img {
        width: 14px;
        height: 14px;
        cursor: pointer;
        margin: 14px 4px 0px 8px;
    }
`;

const SynonymsHolder = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const SynonymsHeader = styled.div`
    margin-bottom: 8px;
`;

const CardContainer = styled.div`
    padding: 0 16px 0 16px;

    .field-wrapper {
        label {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
        }
    }
    .field-container {
        height: 44px;
    }
    label {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
    .new-field-container {
        display: flex;
    }
`;

const AddSynonymButton = styled(Button)`
    margin-left: 16px;
    padding-top: 2px;
`;
