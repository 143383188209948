import React, { useEffect, useRef, useState } from 'react';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';
import styled from '@emotion/styled';
import Loader from '../../../components/general/Loader';
import { css } from '@emotion/css';
import OrgAnalyticsContainer from '../../../hooks/OrgAnalyticsContainer';
import TableHeader from '../../../components/general/TableHeader';
import TableCell from '../../../components/general/TableCell';
import SortedTable from '../../../components/structure/SortedTable';
import ApplicationsSearchResult from '../../../models/applications/api/ApplicationsSearchResult';
import PageError from '../../../components/general/PageError';
import { color_text_link } from '../../../constants/colors';
import { isEqual } from 'lodash';

interface OrgApplicationPerformanceWrapperProps {
    filter: AnalyticsRequestFilter
}

const OrganizationApplicationPerformanceReport = (props: OrgApplicationPerformanceWrapperProps) => {

    const orgAnalyticsContainer = OrgAnalyticsContainer.useContainer();

    const filterRef = useRef(null)

    useEffect(() => {
        const currentOrgId = JSON.parse(localStorage.getItem("CURRENT_ORG"))?.id;
        if (!currentOrgId) return;
        if (!isEqual(filterRef.current, props.filter) && currentOrgId) {
            orgAnalyticsContainer.loadOrgApplicationsPerformanceAnalytics(currentOrgId, props.filter);
            filterRef.current = props.filter;
        }
    }, [props.filter]);

    const formatPercentage = (value: number) => {
        if (!value) return "0 %"
        return `${(value).toFixed(2)} %`
    }

    const columns = [
        {
            id: "app",
            Header: <TableHeader text="Application" />,
            accessor: (m: any) => m,
            Cell: row => (
                <TableCell>
                    <span className={clickableApplicationStyle} onClick={() => window.location.href = `/v/apps/${row.value.applicationId}/analytics`}>
                        {row.value.name}
                    </span>
                </TableCell>
            )
        },
        {
            id: "completion",
            Header: <TableHeader text='Order Completion Rate' />,
            accessor: (m: any) => m.orderCompletionRate,
            Cell: row => (
                <TableCell>
                    {formatPercentage(row.value)}
                </TableCell>
            )
        },
        {
            id: "conv.SuccessRate",
            Header: <TableHeader text='Conv. Success Rate' />,
            accessor: (m: any) => m.conversationalSuccessRate,
            Cell: row => (
                <TableCell>
                    {formatPercentage(row.value)}
                </TableCell>
            )
        },
        {
            id: "transferRate",
            Header: <TableHeader text='Transfer Rate' />,
            accessor: (m: any) => m.callTransferredRate,
            Cell: row => (
                <TableCell>
                    {formatPercentage(row.value)}
                </TableCell>
            )
        },
        {
            id: "immediateTransferRate",
            Header: <TableHeader text='Immediate Transfer Rate' />,
            accessor: (m: any) => m.callImmediateTransferredRate,
            Cell: row => (
                <TableCell>
                    {formatPercentage(row.value)}
                </TableCell>
            )
        },
        {
            id: "Average Order",
            Header: <TableHeader text='Average Order' />,
            accessor: (m: any) => m.averageOrderTotal,
            Cell: row => (
                <TableCell>
                    ${(row.value).toFixed(2)}
                </TableCell>
            ),
        },
    ]

    if (orgAnalyticsContainer.loadingOrgApplicationsPerformanceAnalytics)
        return <Loader />

    return <>
        <div className={applicationPerformancePageStyle}>
            <Header>Organizational Application Performance</Header>
            <SortedTable columns={columns} data={orgAnalyticsContainer.orgApplicationsPerformanceAnalytics} />
        </div>
        <PageError errors={orgAnalyticsContainer.error ? [orgAnalyticsContainer.error] : []} />
    </>

};

const applicationPerformancePageStyle = css`
    padding: 20px 30px 20px 30px;
`

const Header = styled.h3`
    font-size: 18px;
    margin-bottom: 20px;
`;

const clickableApplicationStyle = css`
    cursor: pointer;
    &:hover {
        color: ${color_text_link};
    }
`


export default OrganizationApplicationPerformanceReport;
