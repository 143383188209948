import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import { FormikProps } from 'formik';
import Button from '../../components/general/Button';
import HorizontalContainer from '../../components/structure/HorizontalContainer';
import ImageUploadContainer from '../../components/structure/ImageUploadContainer';
import AvatarFileUploadField from '../../components/forms/AvatarFileUploadField';
import FormFieldsContainer from '../../components/structure/FormFieldsContainer';
import TextField from '../../components/forms/TextField';
import TextAreaField from '../../components/forms/TextAreaField';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import ModalTitle from '../../components/general/ModalTitle';
import ApplicationEnvironmentsContainer, { ApplicationEnvironmentsOption } from '../../state/containers/ApplicationEnvironmentsContainer';
import ApplicationEnvironmentModel from '../../models/applications/api/environments/ApplicationEnvironmentModel';
import CheckboxField from '../../components/forms/CheckboxField';
import ContentCollapsingSection from '../../components/structure/ContentCollapsingSection';
import styled from '@emotion/styled';

const advancedSettingsIcon = require('../../content/images/application-settings/advanced-settings.svg');

export interface ApplicationInformationFormProps {
    appContainer: ApplicationContainer;
    app: ApplicationModel;
    languageIds?: string[];
    environmentOption?: ApplicationEnvironmentsOption;
    environment?: ApplicationEnvironmentModel;
    environmentsContainer: ApplicationEnvironmentsContainer;
    imageUrl?: string;
}

export interface ApplicationInformationFormData {
    name?: string
    invocationPhrase?: string
    shortDescription?: string
    description?: string
    keywords?: string
    imageFile: File
    imageItemId?: string
    featureFlags?: FeatureFlagModel[]
    selectedFlagIds?: string[]
}

const ApplicationInformationForm: React.FC<ApplicationInformationFormProps & FormikProps<ApplicationInformationFormData>> = (props) => {

    const [imageUrl, setImageUrl] = useState(props.app.imageUrl);

    useEffect(() => {
        props.appContainer.loadFeatureFlags();
    }, []);

    const isLoading = props.appContainer.state.isCreatingApplication;

    const appfeatureFlags = props.appContainer.state.currentApp.featureFlags ?? [];

    const allFeatureFlags = props.appContainer.state.featureFlags;

    const handleFeatureFlagsChange = (flag: FeatureFlagModel) => {
        // manage check box
        const flagsCopy = [...props.values.selectedFlagIds];
        if (flagsCopy.includes(flag.id)) {
            flagsCopy.splice(flagsCopy.indexOf(flag.id), 1);   
        } else {
            flagsCopy.push(flag.id);
        }
        props.setFieldValue("selectedFlagIds", flagsCopy);

    };

    const renderFeatureFlags = () => {
        return allFeatureFlags.map((flag, index) => {
            return (
                <CheckboxField
                    key={`KEY ${index}`}
                    name={flag.name}
                    label={flag.name}
                    disabled={isLoading}
                    checked={props.values.selectedFlagIds.includes(flag.id)}
                    onChange={() => handleFeatureFlagsChange(flag)}
                    className={checkboxField}
                />
            )

        });

    };

    const readDataUrl = () => {
        const reader = new FileReader();
        reader.onloadend = e => {
            setImageUrl(reader.result.toString())
        };
    };

    const handleImageSelected = (file: File) => {
        readDataUrl();
        props.setFieldValue("imageFile", file);
    };

    return (
        <form onSubmit={props.handleSubmit}>
            <ModalTitle>App Info</ModalTitle>
            <HorizontalContainer>
                <FormFieldsContainer className={borderedFields}>
                    <NormalSettingsWrapper>
                        <p className="detail-label">
                            Here you can manage the public-facing aspects of your application.
                        </p>
                        <p className="detail-label">
                            Application Name is how you identify your app. Invocation Name is the phrase people will use to call up your application on their device
                        </p>
                        <TextField
                            name="name"
                            disabled={isLoading}
                            required
                            value={props.values.name}
                            label="Application Name:"
                            placeholder="Give your application a name"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                        <TextField
                            name="invocationPhrase"
                            disabled={isLoading}
                            tooltip="Invocation Name is the phrase people will use to call up your application on their device"
                            required
                            value={props.values.invocationPhrase}
                            label="Invocation Name:"
                            placeholder="The name used to start your app"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                        <TextField
                            name="shortDescription"
                            disabled={isLoading}
                            value={props.values.shortDescription}
                            label="Short Description:"
                            placeholder="Just something brief"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                        <TextAreaField
                            name="description"
                            disabled={isLoading}
                            value={props.values.description}
                            label="Detailed Description:"
                            placeholder="Now with more detail"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                        <TextField
                            name="keywords"
                            disabled={isLoading}
                            value={props.values.keywords}
                            label="Keywords:"
                            placeholder="These can help with finding your app/skill"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                    </NormalSettingsWrapper>
                    <ContentCollapsingSection title="Advanced Options" icon={advancedSettingsIcon} isCollapsedByDefault={true} className={collapsedSection}>
                        {renderFeatureFlags()}
                    </ContentCollapsingSection>
                </FormFieldsContainer>
                <ImageUploadContainer>
                    <label>App Avatar</label>
                    <AvatarFileUploadField
                        imageUrl={imageUrl}
                        accept=".png"
                        onChange={handleImageSelected.bind(this)}
                    />
                </ImageUploadContainer>
            </HorizontalContainer>
            <HorizontalContainer>
                <Button
                    loading={isLoading}
                    disabled={isLoading}
                    themes={["primary"]}
                    text="Save"
                    type="submit"
                />
            </HorizontalContainer>
        </form>
    )
}

const checkboxField = css`
    padding: 0 24px;
`;

const NormalSettingsWrapper = styled.div`
    padding-right: 64px;
`;

const collapsedSection = css`
    margin-left: -50px;
    *.card-title-container {
        padding: 24px 24px 24px 40px;
    }
`;

const borderedFields = css`
    border-right: 1px solid #c7cfd2;
    margin-top: 0;

    .detail-label {
        font-family: Muli;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: #8e97a1;
        margin-bottom: 16px;
    }
`

export default ApplicationInformationForm
