import React from "react";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import DropDownSelectField, { Size } from "../../../../components/forms/DropDownSelectField";
import { style_border_default } from "../../../../constants/stylesValues";
import { color_colors_ocean, color_shades_darkest, color_variants_ocean_xlight } from "../../../../constants/colors";
import { MenuRendererProps } from "react-select";
import { Option } from "../../../../models/customAssistant/CustomAssistantConfigurationsOption";

interface CustomAssistantDropdownProps {
    handleOnChange?: (optionValue: string, value: string | boolean) => void
    fieldName?: string
    options?: Option[]
    size?: Size
    value?: string
    className?: string
    searchable?: boolean
}

const CustomAssistantDropdown: React.FC<CustomAssistantDropdownProps> = (props) => {
    return (
        <DropDownSelectField
            placeholder="Select"
            className={props.className}
            name={props.fieldName}
            size={props.size}
            value={props.value}
            onChange={
                (e: any) => {
                    props.handleOnChange(props.fieldName, e.value)
                }
            }
            options={props.options}
            clearable={false}
            searchable={props.searchable ?? false}
            menuRenderer={(menuProps: MenuRendererProps | any) => {
                let options = props.options ?? [];
                if (props.searchable && menuProps.inputValue?.length) {
                    options = options.filter((option: Option) => option.label.toLowerCase().includes(menuProps.inputValue.toLowerCase()));
                }
                return (
                    <MenuOptionsWrapper>
                        <Triangle />
                        {options.map((option, index) =>
                            <SelectOptionMedium key={index} onClick={() => menuProps.selectValue(option)}>
                                <CustomSelectOptionLabelMedium>
                                    <div>
                                        <p>{option.label}</p>
                                    </div>
                                </CustomSelectOptionLabelMedium>
                            </SelectOptionMedium>
                        )}
                    </MenuOptionsWrapper>
                )
            }}
        />
    )
};

const MenuOptionsWrapper = styled.div`
    margin-top: 8px;
`;

const Triangle = styled.div`
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    border-top: ${style_border_default};
    border-left: ${style_border_default};
    background: white;
    margin-top: -7px;
    right: 15px;
    position: absolute;
`;

const SelectOptionMedium = styled.div`
    height: 48px;
    :hover {
        background-color: ${color_variants_ocean_xlight};
        cursor: pointer;
    }
`;

const CustomSelectOptionLabelMedium = styled.div`
    div {
        display: flex;
        align-items: center;
        padding-left: 16px;
        height: 48px;
        border-bottom: ${style_border_default};
    }
    p {
        color: ${color_shades_darkest};
        font-weight: 400;
        font-size: 14px;
    }
    .option-selected {
        color: ${color_colors_ocean};
    }
`;

export default CustomAssistantDropdown;