'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _elementResizeDetector = require('element-resize-detector');

var _elementResizeDetector2 = _interopRequireDefault(_elementResizeDetector);

var _invariant = require('invariant');

var _invariant2 = _interopRequireDefault(_invariant);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ContainerDimensions = function (_Component) {
  _inherits(ContainerDimensions, _Component);

  _createClass(ContainerDimensions, null, [{
    key: 'getDomNodeDimensions',
    value: function getDomNodeDimensions(node) {
      var _node$getBoundingClie = node.getBoundingClientRect(),
          top = _node$getBoundingClie.top,
          right = _node$getBoundingClie.right,
          bottom = _node$getBoundingClie.bottom,
          left = _node$getBoundingClie.left,
          width = _node$getBoundingClie.width,
          height = _node$getBoundingClie.height;

      return { top: top, right: right, bottom: bottom, left: left, width: width, height: height };
    }
  }]);

  function ContainerDimensions() {
    _classCallCheck(this, ContainerDimensions);

    var _this = _possibleConstructorReturn(this, (ContainerDimensions.__proto__ || Object.getPrototypeOf(ContainerDimensions)).call(this));

    _this.state = {
      initiated: false
    };
    _this.onResize = _this.onResize.bind(_this);
    return _this;
  }

  _createClass(ContainerDimensions, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.parentNode = _reactDom2.default.findDOMNode(this).parentNode;
      this.elementResizeDetector = (0, _elementResizeDetector2.default)({
        strategy: 'scroll',
        callOnAdd: false
      });
      this.elementResizeDetector.listenTo(this.parentNode, this.onResize);
      this.componentIsMounted = true;
      this.onResize();
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      this.componentIsMounted = false;
      this.elementResizeDetector.uninstall(this.parentNode);
    }
  }, {
    key: 'onResize',
    value: function onResize() {
      var clientRect = ContainerDimensions.getDomNodeDimensions(this.parentNode);
      if (this.componentIsMounted) {
        this.setState(_extends({
          initiated: true
        }, clientRect));
      }
    }
  }, {
    key: 'render',
    value: function render() {
      (0, _invariant2.default)(this.props.children, 'Expected children to be one of function or React.Element');

      if (!this.state.initiated) {
        return _react2.default.createElement('div', null);
      }
      if (typeof this.props.children === 'function') {
        var renderedChildren = this.props.children(this.state);
        return renderedChildren && _react.Children.only(renderedChildren);
      }
      return _react.Children.only(_react2.default.cloneElement(this.props.children, this.state));
    }
  }]);

  return ContainerDimensions;
}(_react.Component);

ContainerDimensions.propTypes = {
  children: _propTypes2.default.oneOfType([_propTypes2.default.element, _propTypes2.default.func]).isRequired
};
exports.default = ContainerDimensions;