import React, { useState } from "react";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import RadioGroup from "../../../../components/forms/RadioGroup";
import TextField from "../../../../components/forms/TextField";
import HorizontalSeparator from "../../../../components/structure/HorizontalSeparator";
import AvatarFileUploadField from "../../../../components/forms/AvatarFileUploadField";
import CustomAssistantDropdown from "./CustomAssistantDropdown";
import CustomAssistantPreviewLayout from "./CustomAssistantPreviewLayout";
import { color_colors_ocean, color_gradients_green_shift, color_shades_darker, color_shades_darkest, color_text_link, color_variants_shadow_10 } from "../../../../constants/colors";
import { style_border_default } from "../../../../constants/stylesValues";
import { activeInputOptions, autoRunConversationOptions, googleVoiceOptions, initializeWithWelcomeMessageOptions, noTrackingOptions, pollyVoiceOptions, showConversationHistoryOptions, textToSpeechProviderOptions, uITypeOptions, useDraftContentOptions, useOutputSpeechOptions, useVoiceInputOptions } from "../../../../models/customAssistant/CustomAssistantConfigurationFormOptions";
import LanguageSingleSelector from "../languages/LanguageSingleSelector";
import LanguageContainer from "../../../../hooks/LanguageContainer";
import { FormikProps } from "formik";
import MediaContainer from "../../../../state/containers/MediaContainer";
import { CustomAssistantConfigurationsFormData } from "../../../../models/customAssistant/CustomAssistantConfigurationsFormData";

const addIcon = require("../../../../content/images/custom-assistant-deployment/add-circle.svg");

interface SetupTabPanelProps {
    applicationId?: string
    applicationSecret?: string
    handleOnChange?: (optionValue: string, value: string | boolean) => void;
    mediaContainer?: MediaContainer;
}

const SetupTabPanel: React.FC<SetupTabPanelProps & FormikProps<CustomAssistantConfigurationsFormData>> = (props) => {
    const [imageUrl, setImageUrl] = useState(props.values.avatarUrl);

    const languagesContainer = LanguageContainer.useContainer();

    const handleLanguageChange = (selectedLanguageIds: string[]) => {
        props.setFieldValue("defaultLanguageId", selectedLanguageIds[0]);
        const selectedLanguage = languagesContainer.languages?.find(l => l.id === selectedLanguageIds[0]);
        const selectedLanguageShortCode = selectedLanguage.shortCode;
        if (selectedLanguageShortCode) {
            props.setFieldValue("locale", selectedLanguageShortCode);
        }
    };

    const handleImageSelected = (file: File) => {
        readDataUrl(file);
        getConfigImageUrl(file);
    };

    const readDataUrl = (file: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
    };

    const getConfigImageUrl = async (file: File) => {
        const result = await props.mediaContainer.uploadMediaItem(props.applicationId, "customAssistantConfigurationImage", file, null, () => null);
        props.setFieldValue("avatarUrl", result.data.url);
        setImageUrl(result.data.url);
    };

    const handleLayoutOnChange = (optionValue: string, value: string | boolean) => {
        props.setFieldValue(optionValue, value);
        if (value === "minimal") {
            props.setFieldValue("autoRunConversation", true);
        } else {
            props.setFieldValue("autoRunConversation", false);
        }
    };

    return (
        <ConfigurationTabContent>
            <SubHeader>Setup</SubHeader>
            <Description>Configure how your assistant will work</Description>
            <SectionHeaderWrapper>
                <SectionHeaderText>Basics</SectionHeaderText>
                <HorizontalSeparator />
            </SectionHeaderWrapper>
            <LanguageSingleSelector
                labelStyle={languageLabelStyle}
                groupToggle={groupToggleStyle}
                label="Language / Region"
                className={languageSelectStyle}
                languages={languagesContainer.languages}
                selectedLanguageIds={[props.values.defaultLanguageId]}
                onChange={handleLanguageChange}
                optionStyle={languageSingleSelectorOptionStyle}
            />
            <TextField
                name="name"
                tooltip="Name used internally"
                toolTipPosition="right"
                disabled={false}
                value={props.values.name}
                label="Configuration Name"
                placeholder="Configuration Name"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                fieldContainerStyle={fieldContainerStyle}
            />
            {props.values.uiType !== "minimal" &&
                <TextField
                    name="displayName"
                    tooltip="Name end-users see"
                    toolTipPosition="right"
                    disabled={false}
                    value={props.values.displayName}
                    label="Assistant Display Name"
                    placeholder="Display Name"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    fieldContainerStyle={fieldContainerStyle}
                />
            }
            <AvatarFileUploadField
                imageUrl={imageUrl}
                label="Avatar"
                addImageText="Add Image"
                addIcon={addIcon}
                containerStyle={avatarFileUploadContainerStyle}
                previewStyle={avatarFileUploadIconWrapper}
                defaultImageStyle={defaultFileUploadIconStyle}
                imageStyle={avatarFileUploadIconStyle}
                buttonStyle={avatarFileUploadButtonStyle}
                accept=".png,.jpg,.jpeg"
                onChange={handleImageSelected}
            />
            <HorizontalSeparator className={horizontalSeparatorWrapper} />
            <SectionHeaderWrapper>
                <SectionHeaderText>Layout</SectionHeaderText>
                <HorizontalSeparator />
            </SectionHeaderWrapper>
            <Label>Layout</Label>
            <CustomAssistantDropdown
                handleOnChange={handleLayoutOnChange}
                fieldName="uiType"
                options={uITypeOptions}
                size="medium"
                className={dropDownStyleMedium}
                value={props.values.uiType}
            />
            <CustomAssistantPreviewLayout {...props} />
            <SectionHeaderWrapper>
                <SectionHeaderText>Initialization Mode</SectionHeaderText>
                <HorizontalSeparator />
            </SectionHeaderWrapper>
            <Description>How do you want the assistant to start up when the user activates it?</Description>
            <RadioGroupLabel>Welcome Message</RadioGroupLabel>
            <RadioGroup
                checkContainerStyle={checkContainerStyle}
                selectedOptionStyle={selectedOptionStyle}
                onChange={(e) => props.handleOnChange("initializeWithWelcomeMessage", e.value)}
                value={props.values.initializeWithWelcomeMessage}
                options={initializeWithWelcomeMessageOptions}
            />
            {props.values.uiType !== "minimal" &&
                // autoRunConversation defaults to true for the minimal UI type
                <>
                    <RadioGroupLabel>Listening</RadioGroupLabel>
                    <RadioGroup
                        checkContainerStyle={checkContainerStyle}
                        selectedOptionStyle={selectedOptionStyle}
                        onChange={(e) => props.handleOnChange("autoRunConversation", e.value)}
                        value={props.values.autoRunConversation}
                        options={autoRunConversationOptions}
                    />
                </>
            }
            {props.values.uiType !== "minimal" &&
                <>
                    <SectionHeaderWrapper>
                        <SectionHeaderText>Input Types</SectionHeaderText>
                        <HorizontalSeparator />
                    </SectionHeaderWrapper>
                    <RadioGroup
                        checkContainerStyle={checkContainerStyle}
                        selectedOptionStyle={selectedOptionStyle}
                        onChange={(e) => props.handleOnChange("useVoiceInput", e.value)}
                        value={props.values.useVoiceInput}
                        options={useVoiceInputOptions}
                    />
                    <Label>Active Input at open</Label>
                    <CustomAssistantDropdown
                        handleOnChange={props.handleOnChange}
                        fieldName="activeInput"
                        options={activeInputOptions}
                        size="medium"
                        className={dropDownStyleMedium}
                        value={props.values.activeInput}
                    />
                </>
            }
            <SectionHeaderWrapper>
                <SectionHeaderText>Output Types</SectionHeaderText>
                <HorizontalSeparator />
            </SectionHeaderWrapper>
            {props.values.uiType !== "minimal" &&
                <RadioGroup
                    checkContainerStyle={checkContainerStyle}
                    selectedOptionStyle={selectedOptionStyle}
                    onChange={(e) => props.handleOnChange("useOutputSpeech", e.value)}
                    value={props.values.useOutputSpeech}
                    options={useOutputSpeechOptions}
                />
            }
            <Label>Text-to-Speech Provider</Label>
            <CustomAssistantDropdown
                handleOnChange={props.handleOnChange}
                fieldName="textToSpeechProvider"
                options={textToSpeechProviderOptions}
                size="medium"
                className={dropDownStyleMedium}
                value={props.values.textToSpeechProvider}
            />
            {props.values.textToSpeechProvider === "Google" &&
                <>
                    <LabelWrapper>
                        <Label>Google Text-to-Speech Voice</Label>
                        <LabelLink href="https://cloud.google.com/text-to-speech/docs/voices" target="_blank">(more info)</LabelLink>
                    </LabelWrapper>
                    <CustomAssistantDropdown
                        handleOnChange={props.handleOnChange}
                        fieldName="textToSpeechVoice"
                        options={googleVoiceOptions}
                        size="medium"
                        className={dropDownStyleMedium}
                        value={props.values.textToSpeechVoice}
                    />
                </>
            }
            {props.values.textToSpeechProvider === "Polly" &&
                <>
                    <LabelWrapper>
                        <Label>Polly Text-to-Speech Voice</Label>
                        <LabelLink href="https://docs.aws.amazon.com/polly/latest/dg/voicelist.html" target="_blank">(more info)</LabelLink>
                    </LabelWrapper>
                    <CustomAssistantDropdown
                        handleOnChange={props.handleOnChange}
                        fieldName="textToSpeechVoice"
                        options={pollyVoiceOptions}
                        size="medium"
                        className={dropDownStyleMedium}
                        value={props.values.textToSpeechVoice}
                    />
                </>
            }
            {props.values.uiType === "minimal" &&
                <>
                    <RadioGroupLabel>Show Conversation History</RadioGroupLabel>
                    <RadioGroup
                        checkContainerStyle={checkContainerStyle}
                        selectedOptionStyle={selectedOptionStyle}
                        onChange={(e) => props.handleOnChange("showConversationHistory", e.value)}
                        value={props.values.showConversationHistory}
                        options={showConversationHistoryOptions}
                    />
                </>
            }
            <SectionHeaderWrapper>
                <SectionHeaderText>Content</SectionHeaderText>
                <HorizontalSeparator />
            </SectionHeaderWrapper>
            <RadioGroup
                checkContainerStyle={checkContainerStyle}
                selectedOptionStyle={selectedOptionStyle}
                onChange={(e) => props.handleOnChange("useDraftContent", e.value)}
                value={props.values.useDraftContent}
                options={useDraftContentOptions}
            />
            <SectionHeaderWrapper>
                <SectionHeaderText>Analytics</SectionHeaderText>
                <HorizontalSeparator />
            </SectionHeaderWrapper>
            <RadioGroup
                checkContainerStyle={checkContainerStyle}
                selectedOptionStyle={selectedOptionStyle}
                onChange={(e) => props.handleOnChange("noTracking", e.value)}
                value={props.values.noTracking}
                options={noTrackingOptions}
            />
        </ConfigurationTabContent>
    )

};

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const Label = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${color_shades_darkest};
    margin: 16px 0 4px 16px;
`;

const LabelLink = styled.a`
    font-weight: 400;
    font-size: 14px;
    margin: 16px 0 4px 8px;
    color: ${color_text_link}
`;

const SubHeader = styled.h2`
    font-size: 18px;
    color: ${color_shades_darkest};
    font-weight: 400;
`;

const languageSingleSelectorOptionStyle = css`
    font-size: 14px;
    &.item-group {
        height: 48px;
    }
    &.sub-item {
        height: 48px;
        p {
            font-size: 14px;
        }
    }
`;

const languageLabelStyle = css`
    font-size: 14px;
    padding: 0 8px;
`;

const fieldContainerStyle = css`
    height: 48px;
`;

const Description = styled.p`
    font-size: 14px;
    color: ${color_shades_darker};
    margin: 16px 0 32px 0;
`;

const RadioGroupLabel = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${color_shades_darkest};
    margin: 32px 0 4px 0;
`;

const avatarFileUploadButtonStyle = css`
    font-weight: 400;
    font-size: 16px;
    color: ${color_colors_ocean};
    margin-right: 0;
`;

const horizontalSeparatorWrapper = css`
    z-index: -1;
    margin-top: -25px;
`;

const avatarFileUploadContainerStyle = css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .dropZone {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
    margin: 32px 0 0 0;
`;

const defaultFileUploadIconStyle = css`
    width: 40px;
    height: 40px;
    left: 20px;
    top: 20px;
    position: absolute;
`;

const avatarFileUploadIconStyle = css`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    left: 0;
    top: 0;
    position: absolute;
`;

const avatarFileUploadIconWrapper = css`
    width: 80px;
    height: 80px;
    padding: 20px;
    background-color: #ffffff;
    border: ${style_border_default};
    border-radius: 50%;
    box-shadow: 0px 4px 10px ${color_variants_shadow_10};
    cursor: pointer;
`;

const checkContainerStyle = css`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ffffff;
    border: ${style_border_default};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const selectedOptionStyle = css`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-image: ${color_gradients_green_shift};
`;

const groupToggleStyle = css`
    margin-left: auto;
    margin-right: 8px;
    height: 8px;
    width: 8px;
`;

const languageSelectStyle = css`
    margin: 64px 0 32px 0;
    .field .selection {
        height: 48px;
        border-radius: 5px; 
        padding-left: 24px;
    }
    .field-wrapper {
        label {
            position: absolute;
            left: 14px;
            top: -27px;
            font-family: Muli;
            font-size: 14px;
            font-weight: 400;
            color: ${color_shades_darkest};
        }
    }
`;

const SectionHeaderText = styled.h2`
    margin-right: 8px;
    font-size: 16px;
    color: ${color_shades_darkest};
    text-transform: uppercase;
    font-weight: 400;
    white-space: nowrap;
`;

const ConfigurationTabContent = styled.div`
    padding: 24px 24px 0 24px;
`;

const SectionHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 32px 0;
`;

const dropDownStyleMedium = css`
    margin-bottom: 32px;
`;

export default SetupTabPanel;