import styled from '@emotion/styled';
import SelectField from '../../../components/forms/SelectField';
import Button from '../../../components/general/Button';
import { color_colors_ocean, color_shades_dark, color_shades_darker, color_shades_darkest, color_shades_lighter, color_variants_ocean_light_opaque, dark_grey_blue, silver_two, text_blue } from '../../../constants/colors';

export const MainButton = styled(Button)`
    padding: 12px 32px;
    margin: 24px 32px;
    display: flex;
`;

export const AlternateButton = styled(Button)`
    margin: 0px;
`;

export const PublishedStatusRow = styled.div`
    display: flex;
`;

export const LabeledText = styled.div`
    span {
        text-transform: uppercase;
        color: ${color_shades_darker};
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-align: left;        
    }
    p {
        color: ${color_shades_darkest};
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
    }
`;

export const DeploymentSelector = styled(SelectField)`
    width: 60%;
    margin-left: 32px;
`;

export const DeploymentStep = styled.div`
    padding: 24px 0px;
    margin: 0px 32px;
    border-bottom: 1px solid ${color_shades_dark};
`;

export const DeploymentStepNoBorder = styled(DeploymentStep)`
    padding: 24px 0px;
    margin: 0px 32px;
    border-bottom: none;
`;

export const LinkedAccount = styled(DeploymentStep)`
    padding: 16px 0px;
`;

export const StepHeader = styled.div`
    display: flex;
    color: ${color_shades_darkest};
    img {
        margin-right: 16px;
    }
`;

export const DeploymentsCard = styled.div`
    border-radius: 10px;
    background-color: white;
    border: solid 1px ${silver_two};
    margin-bottom: 32px;
    flex-grow: 0;
    font-family: Muli;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${dark_grey_blue};
    min-height: 400px;
`;

export const LabelAndButtonRow = styled.div`
    display: flex;
    padding-top: 16px;
`;

export const PublishStatusRow = styled.div`
    display: flex;
    padding-top: 8px;
    img {
        margin: -3px 8px 8px 0px;
    }
`;

export const WorkflowStepContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    margin: 32px 32px 0px 32px;
    padding-bottom: 64px;
    height: 32px;
    border-bottom: 1px solid ${color_shades_dark};
`;

export const StyledLink = styled.a`
    color: ${color_colors_ocean};
`;

export const CardContainer = styled.div`
    position: relative;
    margin: 32px;
`;

export const HelperText = styled.div`
    padding: 24px 32px 8px 32px;
`;

export const StepHelperText = styled.div`
    padding: 28px 8px 0px 0px;
    font-size: 14px;
`;

export const StepFormContainer = styled.div`
    margin: 24px 0px;
`;

export const StepTitle = styled.div`
    margin-bottom: 4px;
    font-size: 16px;
`;

export const BorderSeparator = styled.div`
    padding: 0px;
    margin: 0px;
    border-bottom: 1px solid ${color_shades_dark};
`;

export const LogoEndpointsHeader = styled.div`
    display: flex;
    flex-direction: row;
    padding: 32px 32px 0px 32px;
    button {
        top: 11px;
        &.button-active{
        color: ${color_colors_ocean};
        background: ${color_variants_ocean_light_opaque};
        border: 1px solid ${color_colors_ocean};
        box-sizing: border-box;
        border-radius: 16px;
        }
    }
    
`;

export const Endpoints = styled.div`
    border-top: 1px solid ${color_shades_dark};
    border-bottom: 1px solid ${color_shades_dark};
    background-color: ${color_shades_lighter};
    padding: 32px 32px;
`;

export const LoginImage = styled.img`
    width: 185px;
    height: 40px;
    cursor: pointer;
    margin: 24px 32px;
`;

export const Filler = styled.span`
    flex-grow: 1;
`;

export const CopyButton = styled(Button)`
    margin-top: 32px;
`;

export const EndpointsHeader = styled.div`
    margin-bottom: 24px;
`;

export const StepNumber = styled.p`
font-size: 14px;
width: 24px;
height: 24px;
border: 1px solid ${color_shades_darker};
border-radius: 16px;
text-align: center;
margin-right: 16px;
line-height: 20px;
`;

export const TopTriangleStyle = styled.div`
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    margin-top: -6px;
    margin-bottom: -6px;
    border-top: solid 1px #CBCCD2;
    border-left: solid 1px #CBCCD2;
    background: #F4F4F6;
    float: right;
    margin-right: 132px;
`
export const Logo = styled.img`
    width: 300px;
    height: auto;
    margin-right: auto;
`;
