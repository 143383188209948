import React from 'react';
import { css } from '@emotion/css';
import { Link } from 'react-router-dom';
import { color_text_default, color_text_light, color_text_link, color_variants_ocean_light_1 } from '../../constants/colors';
const separatorIcon = require('../../content/images/content-explorer/breadcrumb-separator.svg');

interface BreadcrumbProps {
    items: BreadcrumbItem[]
    onItemClick?: (item: BreadcrumbItem) => void
}

export interface BreadcrumbItem {
    id: string
    title: string
    link?: string
    highlighted?: boolean
}

const renderLink = (props: BreadcrumbProps, item, index) => {
    const title = index == 0 ? 'Home' : item.title;
    if (props.onItemClick) {
        return (<p className="item-link"
            onClick={props.onItemClick ? () => props.onItemClick(item) : null}>
            {title}
        </p>)
    }
    else {
        return (<Link to={item.link}
            onClick={props.onItemClick ? () => props.onItemClick(item) : null}>
            {title}
        </Link>)
    }
}

const Breadcrumb: React.SFC<BreadcrumbProps> = (props) => (
    <div className={containerStyle}>
        {props.items?.map((item, index) => {
            const isLast = index >= props.items.length - 1;
            const title = index == 0 ? 'Home' : item.title;
            return (
                <div className={`breadcrumb-item ${item.highlighted ? 'highlighted' : ''}`} key={index}>
                    {isLast
                        ? <p>{title}</p>
                        : renderLink(props, item, index)
                    }
                    {!isLast && <img src={separatorIcon} />}
                </div>
            )
        })}
    </div>
)

const containerStyle = css`
    display: flex;
    align-items: center;
    flex: 1;

    .breadcrumb-item {
        display: flex;
        align-items: center;

        img {
            margin: 0 10px;
        }

        p,a {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
        }

        p {
            color: ${color_text_default};
        }
        a, .item-link {
            color: ${color_text_light};
            border-radius: 5px;
            padding: 0 4px;
            cursor: pointer;
            &:hover {
                color: ${color_text_link};
                background: ${color_variants_ocean_light_1};
            }
        }

        &.highlighted {
            color: ${color_text_link};
            background: ${color_variants_ocean_light_1};
            border-radius: 5px;
            padding: 0 4px;
        }
    }
`

export default Breadcrumb;