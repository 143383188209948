import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import Loader from '../../../components/general/Loader';
import ApplicationCard from './ApplicationCard';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import RoundedTwoOptionButton from '../../../components/forms/RoundedTwoOptionButton';
import ApplicationWithMembers from '../../../models/applications/api/ApplicationWithMembers';
import NumberIncrementSelector from '../../../components/forms/NumberIncrementSelector';
import { Option } from 'react-select';
import { color_shades_dark, color_shades_darker, color_shades_darkest, color_shades_lighter } from '../../../constants/colors';
import SearchField from '../../../components/forms/SearchField';
import VerticalSeparator from '../../../components/structure/VerticalSeparator';
import Button from '../../../components/general/Button';
import SubscriptionContainer from '../../../state/containers/SubscriptionContainer';
import { Link } from 'react-router-dom';
import ApplicationTableItem from './ApplicationTableItem';
import TooltipWrapper from '../../../components/general/TooltipWrapper';
import FlatRoundedSelectField from '../../../components/forms/FlatRoundedSelectField';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import { CardTableOption } from '../../../models/general/CardTableOption';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import DeleteConfirmation from '../../featureContent/components/DeleteConfirmation';
import WebhookContainer from '../../../state/containers/WebhookContainer';
import WebhookInstanceManager from '../../webhookInstanceManager';
import MemberContainer from '../../../state/containers/MemberContainer';
import UserContainer from '../../../state/containers/UserContainer';
import TelephonyDeploymentContainer from '../../../hooks/TelephonyDeploymentContainer';
import EmptyView from '../../applicationModules/components/EmptyView';
import { breakpoint_small } from '../../../../shared/constants/breakpoints';

const addIcon = require("../../../content/images/content-explorer/add-white.svg");

interface ApplicationListViewProps {
    appsContainer: ApplicationContainer;
    orgContainer: OrganizationContainer;
    subContainer: SubscriptionContainer;
    webhookContainer: WebhookContainer;
    organizationId: string;
    userContainer: UserContainer;
    memberContainer: MemberContainer;
}

const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

const ApplicationListView: React.FC<ApplicationListViewProps> = (props) => {
    const [view, setView] = useState<CardTableOption>();
    const [page, setPage] = useState(1);
    const [take, setTake] = useState("50");
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState("NameAscending");
    const [deleteApplicationId, setDeleteApplicationId] = useState<string>();
    const [webhookInstanceApplicationId, setWebhookInstanceApplicationId] = useState<string>();

    const telephonyDeploymentContainer = TelephonyDeploymentContainer.useContainer();
    const timerRef = React.useRef<NodeJS.Timer | null>(null);

    const reloadApps = () => {
        const promise = props.appsContainer.searchForOrgApplications(props.organizationId, page, parseInt(take), sortBy, search);
        promise.then(result => {
            let apps = result.data.applications ?? [];
            let appIds = apps.map(a => a.id);
            props.appsContainer.getFolderAndItemCountsForApplications(props.organizationId, appIds);
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!props.userContainer.state.currentUser) {
                await props.userContainer.getUser();
            }
            await props.memberContainer.checkIsOrgAdmin(props.organizationId, props.userContainer.state.currentUser?.id);
            setSortBy(props.appsContainer.state.applicationListPreferences?.sortBy ?? "NameAscending");
            setTake(props.appsContainer.state.applicationListPreferences?.take ?? "50");
            setView(props.appsContainer.state.applicationListPreferences?.view ?? "card");
            reloadApps();
            props.subContainer.loadSubscription(props.organizationId);
        };

        fetchData();
    }, []);

    useEffect(() => {
        reloadApps();
    }, [page, take, sortBy, search]);

    const onOptionSelect = async (appId: string, option: string) => {
        if (option === "Delete") {
            setDeleteApplicationId(appId);
        } else if (option === "Webhooks") {
            setWebhookInstanceApplicationId(appId);
        }
    };

    const closeModals = () => {
        setDeleteApplicationId(null);
        setWebhookInstanceApplicationId(null);
    };

    const deleteApplication = async () => {
        if (deleteApplicationId !== null) {
            const telephonyResponse = await telephonyDeploymentContainer.getTelephonyDeployments(deleteApplicationId);
            if (telephonyResponse.data) {
                const telephonyDeploymentsToDelete = telephonyResponse?.data?.filter(telephonyDeployment => telephonyDeployment.applicationId === deleteApplicationId);
                if (telephonyDeploymentsToDelete.length > 0) {
                    for (const telephonyDeployment of telephonyDeploymentsToDelete) {
                        await telephonyDeploymentContainer.deleteTelephonyDeployment(deleteApplicationId, telephonyDeployment.phoneNumber);
                    }
                }
            }
            await props.appsContainer.disableApplication(deleteApplicationId);
            reloadApps();
            closeModals();
        }
    };

    const renderApplicationCards = (apps: ApplicationWithMembers[]) => {
        return apps.length === 0 ? (
            <EmptyView
                imageStyle={emptyViewImageStyle}
                title="It's quiet in here!"
                description=""
            />
        ) : (
            <div className={appCardContainer}>
                {apps.map((app) => (
                    <ApplicationCard
                        onOptionSelect={onOptionSelect}
                        key={app.id}
                        app={app}
                        counts={props.appsContainer.getFolderAndItemCount(app.id)}
                    />
                ))}
            </div>
        );
    };

    const renderApplicationTable = (apps: ApplicationWithMembers[]) => {
        return apps.length === 0 ? (
            <EmptyView
                imageStyle={emptyViewImageStyle}
                title="It's quiet in here!"
                description=""
            />
        ) : (
            <div className={appTableContainer}>
                <div className="top-row">
                    <div className="apps">APPS</div>
                    <div className="content">CONTENT</div>
                    <div className="participants">PARTICIPANTS</div>
                </div>
                {apps.map(app => <ApplicationTableItem
                    onOptionSelect={onOptionSelect}
                    key={app.id}
                    app={app}
                    counts={props.appsContainer.getFolderAndItemCount(app.id)} />)}
            </div>
        );
    };

    const incrementPage = () => {
        const total = props.appsContainer.state.applicationsCount;
        const nextAppToLoadIndex = page * parseInt(take) + 1;
        if (nextAppToLoadIndex <= total) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const decrementPage = () => {
        if (page !== 1) {
            setPage(prevPage => prevPage - 1);
        }
    };

    const toggleView = () => {
        const newView: CardTableOption = view === "card" ? "table" : "card";
        setView(newView);
        props.appsContainer.updateApplicationListPreferences(
            {
                take,
                sortBy,
                view: newView
            }
        );
    };

    const handleSortByChange = (o: Option) => {
        const sortByString = o.value.toString();
        setSortBy(sortByString);
        setPage(1);
    };

    const handleTakeChange = (o: Option) => {
        const takeString = o.value.toString();
        setTake(takeString);
        setPage(1);
    };

    const handleSearch = (searchEvent: React.ChangeEvent<HTMLInputElement>) => {
        clearTimeout(timerRef.current as NodeJS.Timer);
        setSearch(searchEvent.target.value);
        setPage(1);
        timerRef.current = setTimeout(reloadApps, WAIT_INTERVAL);
    };

    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            reloadApps();
        }
    };

    const renderAddNewButtion = (addNewDisabled: boolean) => {
        return (
            <Link
                to="/v/create-app"
                onClick={(e) => { if (addNewDisabled) e.preventDefault() }} aria-disabled={addNewDisabled}
            >
                <Button
                    text="Add New"
                    themes={["primary-small"]}
                    rightIcon={addIcon}
                    className="add-new-button ac-create-app-button"
                    disabled={addNewDisabled}
                />
            </Link>
        );
    };

    const applications = props.appsContainer.state.orgApplicationsList ?? [];
    const applicationToDelete = applications.find(a => a.id === deleteApplicationId);
    const subsLoading = props.subContainer.state.subscription?.subscriptionType?.maxSandboxes == null || props.appsContainer.state?.orgApplicationsList == null;
    let appsCount = 0;
    let maxApps = 1;

    if (!subsLoading) {
        appsCount = props.appsContainer.state.applicationsCount;
        maxApps = Math.max(props.subContainer.state.subscription.subscriptionType.maxApps, props.subContainer.state.subscription.subscriptionType.maxSandboxes);
    }

    const addNewDisabled = (appsCount >= maxApps) || !props.memberContainer.state.isOrgAdmin;
    const maxAppsString = maxApps > 100000 ? "∞" : `${maxApps}`;
    const tooltipText = (appsCount >= maxApps)
        ? `Your current license allows for the\ncreation of ${maxAppsString} app${appsCount > 1 ? 's' : ''}. To increase this number, please have\nan org admin contact sales@voicify.com`
        : "You do not have permission to add a new app";

    return (
        <>
            <HorizontalContainer className={searchAndModify}>
                <SearchField
                    id="1"
                    name={"searchField"}
                    placeholder={"Search"}
                    disabled={false}
                    onChange={handleSearch}
                    onKeyDown={handleSearchKeyDown}
                    className={searchField} />
                <div className={"table-grid"}><RoundedTwoOptionButton updateViewValue={toggleView} selectedOption={view === "table"} /></div>
                <VerticalSeparator className="separator" />
                <div className="app-count">{subsLoading ? "-" : `${appsCount} of ${maxAppsString}`}</div>
                <div className="apps-name">Apps</div>
                {
                    addNewDisabled ?
                        <TooltipWrapper text={tooltipText} className={toolTipStyle} place="bottomRight">
                            {renderAddNewButtion(addNewDisabled)}
                        </TooltipWrapper>
                        :
                        renderAddNewButtion(addNewDisabled)
                }
            </HorizontalContainer>
            <HorizontalContainer className={pageAndSort}>
                <FlatRoundedSelectField
                    options={[{ label: "Name - Alphabetical", value: "NameAscending" },
                    { label: "Name - Reverse Alphabetical", value: "NameDescending" },
                    { label: "Created Date - Oldest First", value: "CreatedDateAscending" },
                    { label: "Created Date - Latest First", value: "CreatedDateDescending" }]}
                    onChange={handleSortByChange}
                    value={sortBy}
                    label="Arrange by"
                    className={arrangeBy}
                />
                <FlatRoundedSelectField
                    options={[{ label: "10", value: "10" },
                    { label: "25", value: "25" },
                    { label: "50", value: "50" }]}
                    onChange={handleTakeChange}
                    value={take}
                    label="Display at once"
                    className={takeStyle}
                />
                <div className={pageContainer}>
                    <div className={leftSideLabel}>Page</div>
                    <NumberIncrementSelector
                        useShadow={false}
                        selectedNumber={`${page}`}
                        updateValue={(b: boolean) => b ? incrementPage() : decrementPage()}
                        showNumber={true} />
                </div>
            </HorizontalContainer>
            {
                props.appsContainer.state.isLoadingApps ?
                    <Loader />
                    :
                    view === "card" ? renderApplicationCards(applications) : renderApplicationTable(applications)
            }

            {deleteApplicationId != null &&
                <ConfirmationDialog title={`Delete Application`}
                    deleteText="Yes, Delete"
                    isLoading={props.appsContainer.state.isLoadingApps}
                    onClose={closeModals}
                    onConfirm={deleteApplication}>
                    <DeleteConfirmation itemLabel={`${applicationToDelete?.name}?`} />
                </ConfirmationDialog>
            }
            <WebhookInstanceManager
                show={webhookInstanceApplicationId != null}
                webhookContainerId={webhookInstanceApplicationId}
                applicationId={webhookInstanceApplicationId}
                webhookInstanceScope="Application"
                onClose={closeModals}
                onFinish={(webhookInstances) => { props.appsContainer.updateWebhookInstancesForApplication(webhookInstanceApplicationId, webhookInstances); }}
            />
        </>
    );
};

const searchAndModify = css`
    margin: 12px 0 12px 32px;
    div {        
        box-shadow: none;
    }
    .number-container {
        box-shadow: none;
    }
    .field-container {
        height: 32px;
        border-radius: 16px;
        margin-top: 0px;
    }
    .table-grid {
        margin: 0px 16px;
    }
    .app-count {
        height: 32px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: ${color_shades_darkest};
        text-align: center;
        background-color: ${color_shades_lighter};
        padding: 4px 16px;
        border-radius: 16px;
        padding-top: 6px;
    }
    .apps-name {
        font-size: 14px;
        line-height: 20px;
        height: 32px;
        font-style: normal;
        font-weight: normal;
        margin-left: 0px;
        padding: 6px 16px 4px 8px;  
    }
    .add-new-button {
        margin: 0px;
        margin-right: 32px;
    }
    .separator {
        margin-right: 16px;
        height: 32px;
    }
`;

const leftSideLabel = css`    
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${color_shades_darkest};
    margin-right: 16px;
    margin-top: 8px;
`;

const searchField = css`
    margin-bottom: 0px;
`;

const toolTipStyle = css`
    max-width: 240px;
    right: 32px;
    left: initial !important;
    span {
        text-align: center;
        display: block;
        padding: 0px 8px;
    }
`;

const pageAndSort = css`
    border-bottom: 1px solid ${color_shades_dark};
    border-top: 1px solid ${color_shades_dark};
`;

const pageContainer = css`
    display: flex;
    flex-direction: row;
    margin: 12px 32px 0px 0px; 
`;

const arrangeBy = css`    
    margin: 12px auto 12px 32px; 
    .Select {
        width: 240px
    }
`;

const takeStyle = css`    
    margin: 12px 32px 12px 0px; 
    .Select {
        width: 80px
    }
`;

const appCardContainer = css`
    display: grid;
    width: 100%;
    padding-right: 32px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
`;

const appTableContainer = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    .top-row {
        display: flex;
        flex-direction: row;
        border-bottom: solid 1px ${color_shades_dark};
        height: 40px;
        padding-left: 32px;
        padding-right: 32px;
        div {
            margin-top: auto;
            margin-bottom: auto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${color_shades_darker};
        }
        .apps {
            width: 50%;
        }
        .content {
            width: 30%;
        }
        .participants {
            width: 20%;
            margin-left: -8px
        }
    }
`;

const emptyViewImageStyle = css`
    ${breakpoint_small} {
        height: 350px;
        width: 350px;
    }
`;

export default ApplicationListView;
